import React, {useRef, useState} from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import Select from "react-select";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { getWatchlistNumber } from "../../../services/chartService";
import "./ManagerReportsStyle.css";

const FilterSection = ({ filterOptionsLoading ,filtersOptions, data, onFilterChange, filterFields, onFilterClick }) => {
    const typeAheadRefProject= useRef(null);
    const [optionsWorklist,setOptionsWorklist] = useState([]);
    const [selectedOptionWorkList, setSelectedOptionWorkList] = useState([""]);

    const handleSearchWorkList = async (query) => {
        let postData = {
          worklist: query,
        };
        const items = await getWatchlistNumber(postData);
        const workList = items?.data?.typeaheadWorkListRecord;
        setOptionsWorklist(workList);
    };

    const handleInputChange = (input) => {
        if (input === '') {
          setSelectedOptionWorkList('');
        }
    }

    const getType = (val) => {
        if(val){
            if (val.toLowerCase() === 'integer') {
                return "number"
            }
            if (val.toLowerCase() === 'string') {
                return "text"
            }
            return val
        }
    }

    const getFilterData = () => {
        let tempFilterFields = []
        let currentDate = new Date()
        let currentMonth = currentDate.getMonth()
        let projectFilterExists = false;
        for (let a in filterFields) {
            let item = filterFields[a]
            let options = []
            if (filtersOptions[a]) {
                if (item.filterType === 'select') {
                    filtersOptions[a].forEach((item) => {
                        options.push({
                            label: item.name,
                            value: item.id
                        })
                    })
                }
            }
            if(projectFilterExists && (item.key === 'project_name' || item.key === 'worklist_no')){
                continue;
            }
            tempFilterFields.push(
                <div key={item.label} className="col-lg-3 ">
                    <label className="form-label w-250px">{item.label === 'Project Name' || item.label === 'Project #' ? 'Project' : item.label} </label>
                    {
                        item.filterType.toLowerCase() === "select" ? 
                        <Select  isLoading={filterOptionsLoading} name="colors" className="filterSelect" classNamePrefix="select" value={item.value} options={options} isMulti={item.multiselect} onChange={(e) => { onFilterChange(e, a) }} isClearable={true} />
                        : 
                        item.filterType.toLowerCase() === "typeahead" ?
                        <AsyncTypeahead
                            filterBy={() => true}
                            isLoading={false}
                            id="worklistdata"
                            placeholder="..."
                            ignoreDiacritics={true}
                            labelKey={(option)=>`${option.name} (${option.worklist_no})`}
                            options={optionsWorklist}
                            value={selectedOptionWorkList}
                            defaultInputValue={''}
                            minLength={1}
                            onSearch={handleSearchWorkList}
                            onInputChange={handleInputChange}
                            renderMenuItemChildren={(option) => (
                            <>
                                <span>{option?.name} ( {option.worklist_no} )</span>
                            </>
                            )}
                            ref={typeAheadRefProject}
                            onChange={(selected) => {
                            setSelectedOptionWorkList([selected[0]?.worklist_no]);
                            onFilterChange(item.key === "project_name" ? selected[0]?.name : selected[0]?.worklist_no, a)
                            }}
                        /> 
                        :
                        ( item.filterType.toLowerCase() === "date" ?
                            <DateRangePicker onApply={(e, j) => {
                                onFilterChange(`${moment(j.startDate).format('L')}-${moment(j.endDate).format('L')}`, a)
                            }}
                                initialSettings={{ startDate: new Date(), endDate: new Date(), maxDate: new Date(currentDate.getFullYear(), currentMonth+1, 0), locale: { cancelLabel: 'Clear' } }}
                                onCancel={() => {handleDatePickerCancel('',a)}}
                            >
                                <p className="form-control text-sm-left" >{filterFields[a].value ? filterFields[a].value : `Select date`}</p>
                            </DateRangePicker>
                            :
                            <input min={item.min?? null} max={item.max?? null} type={getType(item.type)} value={filterFields[a].value} className="form-control " onChange={(e) => {
                                let value = e.target.value;
                                if (item.type.toLowerCase() === 'integer' || item.type.toLowerCase() === 'number') 
                                    value = parseInt(e.target.value);
                                onFilterChange(value, a)
                                }} placeholder="" 
                            />
                        )
                    }
                </div>
            )
            if (item.key === 'project_name' || item.key === 'worklist_no') {
                projectFilterExists = true;
            }
        }
        return tempFilterFields
    }
    const handleDatePickerCancel = (value, fieldName) => {
        onFilterChange(value, fieldName);
    };
    
    return (
        <div className="card-body border-0 py-0 minimize reports-filter-section">
            {
                Object.keys(filterFields).length > 0 ?
                <>
                    <div className="row mb-5 horizontal-scrollable">
                        {getFilterData()}
                    </div> <div className="d-flex justify-content-end p-5 " >
                        <button onClick={onFilterClick} className="btn btn-primary fs-6">Filter</button>
                    </div>
                </> 
                : 
                <div className="d-flex justify-content-center pb-20" >No Filters Selected</div>
            }
        </div>
    )
}
export default FilterSection;