import {React, useState, useEffect} from "react";
import { Modal } from "react-bootstrap";
import { imageIdGenerate } from "../../utils/custom";
import {logHourNotificationReply,getLogHourNotificationReply} from "../../services/userService";

function ReplyLogHourNotification({showMainModalBox,setShowMainModalBox,notification,date, userName, imageUrl, userId, notificationId}) {
    const [reply, setReply] = useState("");
    const [replyDisplay, setReplyDisplay] = useState("");

    const handleSubmition = () => {
        logHourNotificationReply(reply,userId,notificationId)
        setShowMainModalBox(!showMainModalBox)
    }

    const handleReply = (e) => {
        setReply(e?.target?.value ?? "")
    }

    useEffect(() => {
       getLogHourNotificationReply(notificationId).then(response => setReplyDisplay(response))
       // eslint-disable-next-line
    },[])

    return (
        <>
            <Modal show={showMainModalBox} size="lg" centered>
                <div>
                    <div>
                        <Modal.Header >
                            <h2 className="fw-bold">Reply for log hour</h2>
                            <div className="btn btn-icon btn-sm btn-active-icon-primary" onClick={()=>{setShowMainModalBox(!showMainModalBox)}}>
                                <i className="fas fa-times"></i>
                            </div>
                        </Modal.Header>
                        <Modal.Body className="modal-body scroll-y">
                            <span className="mb-5">Date: {`${date}`}</span>
                            <span className="ms-5 mb-5">Message:{`${notification}`}</span>
                            <span className="ms-5 mb-5">User image: <div className="symbol symbol-45px symbol-circle"><img alt="Pic" src={imageUrl ?? `../assets/media/avatars/300-${imageIdGenerate(userId)}.jpg`} /></div></span>
                            <span className="ms-5 mb-5">Username: {`${userName}`}</span>
                            {replyDisplay?.message?.map((res) => <div className="card d-flex flex-row p-5 mt-1 border border-dashed border-gray-300 rounded chart-comments-reply" style={{ marginLeft: "22px" }} key={""}>
                                <div className="symbol symbol-45px symbol-circle">
                                    <img alt="Pic" src={res?.user_image_url ?? `../assets/media/avatars/300-${imageIdGenerate(userId)}.jpg`} />
                                </div>
                                <div className="flex-fill ms-5">
                                    <div className="d-flex flex-wrap justify-content-between">
                                        <span className="h6 mb-1 author" data-msgid-author={""} data-msg-author-name={""}>
                                            {`${res?.user_first_name + " " + res?.user_last_name }`}
                                            <br />
                                            <small className="text-muted msg-time">{`${res?.createdAt}`}</small>
                                        </span>
                                    </div>
                                    <span className="text-muted" data-msg-body={""}>
                                        {res?.user_message}
                                    </span>
                                    <div className="d-flex flex-wrap justify-content-between">
                                        <span className="h6 mb-1 author" />
                                            <small className="text-muted msg-time">
                                                <span className="badge bg-light-success text-success">{""}</span>
                                            </small>
                                    </div>
                                </div>
                            </div>)}
                            <textarea id="rejectTextArea" className="form-control mt-5" name="comment_msg" placeholder="Reply message..." onChange={(e) => { handleReply(e) }} defaultValue={""} />
                            <button type="submit" id="manager_submit" onClick={handleSubmition} class="btn fw-bold btn-primary mt-5">Save</button>
                        </Modal.Body>
                    </div>
                </div>
            </Modal>
            </>
    );
}

export default ReplyLogHourNotification;