import { axios } from "../utils/axios";
const baseUrl = process.env.REACT_APP_API_HOST;

export const checkUserAuthenticatedAndAttending = async () => {
    try {
        let response = await axios.get(`${baseUrl}/monitor/heartbeat`);
        return response;
    } catch (error) {
        error?.message
            ? window.toastr.error(error.message)
            : console.log(error);
    };

}