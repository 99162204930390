import React from "react";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import moment from "moment/moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { UpdateProjectDetails } from "../../../services/managerWorkListService";
import { userRoleConstants } from "../../../utils/constants";
const WorklistEditProjectModal = ({ projectAllDetails, showEditProjectModalBox, handleCloseModal, masterData, projectMembers, handleProjectDetailsData}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      worklist_name: "",
      Client: "",
      ProcessId: "",
      start_date: "",
      end_date: "",
      OwnerId: "",
      duration: "",
    },
  });

  useEffect(() => {
    setValue("worklist_name", projectAllDetails?.name);
    setValue("duration", projectAllDetails?.duration);
    setValue("OwnerId", { value: projectAllDetails?.project_user?.value, label: projectAllDetails?.project_user?.label });
    setValue("Client", { value: projectAllDetails?.project_client?.value, label: projectAllDetails?.project_client?.label });
    setValue("ProcessId", { value: projectAllDetails?.project_process?.value, label: projectAllDetails?.project_process?.label });
    setValue("StatusId", { value: projectAllDetails?.project_status?.value, label: projectAllDetails?.project_status?.label });
    if (projectAllDetails?.start_date) {
      setValue("start_date", projectAllDetails?.start_date);
    } else {
      setValue("start_date", "");
    }
    if (projectAllDetails?.end_date) {
      setValue("end_date", projectAllDetails?.end_date);
    } else {
      setValue("end_date", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectAllDetails]);

  const renderClients = () => {
    return masterData?.clients?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };
  const renderProcessId = () => {
    return masterData?.processes?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };
  const renderStatusId = () => {
    return masterData?.worklist_statuses?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };
  const renderOwner = () => {
    return projectMembers
      ?.map((item) => {
        const { ADMIN_ID, MANAGER_ID } = userRoleConstants;
        if (item?.RoleId === MANAGER_ID) {
          return {
            label: `${item?.first_name} ${item?.last_name}`,
            value: item?.id,
          };
        } else if (item?.RoleId === ADMIN_ID) {
          return {
            label: `${item?.first_name} ${item?.last_name}`,
            value: item?.id,
          };
        }
        return null; // Handle other cases if needed
      })
      .filter((item) => item !== null);
  };

  const handleDurationChange = (e) => {
    const newDurationValue = parseInt(e.target.value);
    if (!isNaN(newDurationValue) && newDurationValue >= 0 && newDurationValue <= 100) {
      setValue("duration", newDurationValue);
      const newEndDate = moment(projectAllDetails?.start_date, "DD/MM/YYYY").add(newDurationValue, "weeks").format("DD-MM-YYYY");
      setValue("end_date", newEndDate);
    }
  };
  const handleStartDateChange = (selectedDates) => {
    const newStartDate = moment(selectedDates[0]);
    setValue("start_date", newStartDate.format("DD/MM/YYYY"));
    const durationValue = getValues("duration");
    if (!isNaN(durationValue) && durationValue >= 0 && durationValue <= 100) {
      const newEndDate = newStartDate.clone().add(durationValue, "weeks").format("DD/MM/YYYY");
      setValue("end_date", newEndDate);
    }
  };
  const handleEndDateChange = (selectedDates) => {
    const newEndDate = moment(selectedDates[0]).format("DD/MM/YYYY");
    const weeksDuration = moment(newEndDate, "DD-MM-YYYY").diff(moment(projectAllDetails?.start_date, "DD-MM-YYYY"), "weeks");
    if (!isNaN(weeksDuration) && weeksDuration >= 0 && weeksDuration <= 100) {
      setValue("end_date", newEndDate);
      setValue("duration", weeksDuration);
    }
  };

  async function handleRegistration(data) { 
    let start_date = "";
    let end_date = "";
    if (data?.start_date) {
      start_date = moment(data?.start_date, "DD/MM/YYYY").format("DD-MM-YYYY");
    }
    if (data?.end_date) {
      end_date = moment(data?.end_date, "DD/MM/YYYY").format("DD-MM-YYYY");
    }
    let obj = {
      name: data?.worklist_name,
      start_date: start_date,
      end_date: end_date,
      ProcessId: data?.ProcessId?.value,
      client: data?.Client?.value,
      status: data?.StatusId?.value,
      owner: data?.OwnerId?.value,
      duration: data?.duration,
    };
    const response = await UpdateProjectDetails(projectAllDetails?.id, obj);
    if (response?.success === true) {
      handleCloseModal();
      window.toastr.success(response?.message);
      handleProjectDetailsData(response);
    }
    if (response?.success === false) {
      window.toastr.error(response?.message);
    }
  }
  return (
    <>
      <Modal show={showEditProjectModalBox} size="lg" centered>
        <div>
          <div>
            <Modal.Header className="modal-header py-4">
              <h2 className="fw-bold">Edit New Project</h2>
              <Modal.Title className="btn btn-icon btn-sm btn-active-icon-primary" onClick={() => handleCloseModal()}>
                <span className="svg-icon svg-icon-1">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                  </svg>
                </span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body scroll-y my-5">
              <form onSubmit={handleSubmit(handleRegistration)}>
                <div className="row mb-4">
                  <div className="col-6">
                    <label className="required form-label">Project Name</label>
                    <input type="text" className="form-control" placeholder="" name="worklist_name" {...register("worklist_name", { required: "Required" })} />
                    <small className="text-danger">{errors?.worklist_name && errors.worklist_name.message}</small>
                  </div>
                  <div className="col-6">
                    <label className=" required form-label">Client </label>
                    <Controller name="Client" control={control} rules={{ required: "Required" }} render={({ field }) => <Select {...field} options={renderClients()} />} />
                    <small className="text-danger">{errors?.Client && errors.Client.message}</small>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-6">
                    <label className="required form-label">Type </label>
                    <Controller name="ProcessId" control={control} rules={{ required: "Required" }} render={({ field }) => <Select {...field} options={renderProcessId()} />} />
                    <small className="text-danger">{errors?.ProcessId && errors.ProcessId.message}</small>
                  </div>

                  <div className="col-6">
                    <label className="form-label">Project Owner</label>
                    <Controller name="OwnerId" control={control} rules={{ required: "Required" }} render={({ field }) => <Select {...field} options={renderOwner()} />} />
                    <small className="text-danger">{errors?.OwnerId && errors.OwnerId.message}</small>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-6">
                    <label className="fs-6 fw-semibold mb-2 required form-label">
                      <span>Start Date</span>
                    </label>
                    <Controller
                      name="start_date"
                      control={control}
                      render={({ field }) => (
                        <Flatpickr
                          id="start_date"
                          className="form-control"
                          placeholder=""
                          options={{ dateFormat: "d-m-Y", animate: true }}
                          value={field.value}
                          onChange={(date) => {
                            field.onChange(date[0]);
                            handleStartDateChange(date);
                          }}
                          rules={{ required: "Required" }}
                        />
                      )}
                    />
                    <small className="text-danger">{errors?.start_date && errors.start_date.message}</small>
                  </div>
                  <div className="col-6">
                    <OverlayTrigger placement={"top"} overlay={<Tooltip>Possible values: 0 to 100</Tooltip>}>
                      <label className="form-label">
                        Duration(weeks) <i className="fas fa-exclamation-circle ms-1 fs-6"></i>
                      </label>
                    </OverlayTrigger>
                    <input className="form-control" name="duration" type="number" min="0" max="100" autoComplete="off" {...register("duration", { required: "Required" })} onChange={handleDurationChange} />
                    <small className="text-danger">{errors?.duration && errors.duration.message}</small>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-6">
                    <label className="fs-6 fw-semibold mb-2 required form-label">
                      <span>End Date</span>
                    </label>
                    <Controller
                      name="end_date"
                      control={control}
                      render={({ field }) => (
                        <Flatpickr
                          id="end_date"
                          className="form-control"
                          placeholder=""
                          options={{ dateFormat: "d-m-Y", 
                          animate: true,
                          minDate: moment(projectAllDetails?.start_date, "DD/MM/YYYY").format("DD-MM-YYYY"),
                          maxDate: moment(projectAllDetails?.start_date, "DD/MM/YYYY").add(100, "weeks").format("DD-MM-YYYY")
                        }}
                          value={field.value}
                          onChange={(date) => {
                            field.onChange(date[0]);
                            handleEndDateChange(date);
                          }}
                        />
                      )}
                      rules={{ required: "Required" }}
                    />
                    <small className="text-danger">{errors?.end_date && errors.end_date.message}</small>
                  </div>
                  <div className="col-6">
                    <label className="required form-label">Status </label>
                    <Controller name="StatusId" control={control} rules={{ required: "Required" }} render={({ field }) => <Select {...field} options={renderStatusId()} />} />
                    <small className="text-danger">{errors?.StatusId && errors.StatusId.message}</small>
                  </div>
                </div>
                <div className="flex-right justify-content-start">
                  <button type="submit" className="btn btn-primary me-3">
                    <span className="indicator-label">Submit</span>
                    <span className="indicator-progress">
                      Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                  <button type="reset" className="btn btn-light me-3" onClick={() => handleCloseModal()}>
                    Cancel
                  </button>
                </div>
              </form>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default WorklistEditProjectModal;
