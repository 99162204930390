import { useContext, useState, useEffect } from "react";
import Select from "react-select";
import Configurationcontext from "../../Context/ConfigrationContext/Configurationcontext";
import configurationConstants from "../../apis/configuration/configurationConstants";
import { deleteButton,getMasterData } from "../../services/chartService";

const GeneralConfiguration = ({ handleClose, location, clientCurrentId, fetchConfigurationData, setTechStackCategories, dataSuccessfullyAdded, techStackCategories, setDataSuccessfullyAdded}) => {

    const data = useContext(Configurationcontext);
    const configurationProvider = useContext(Configurationcontext)
    const [masterData, setMasterData] = useState();
    const processes = "processes";
    const specialties = "specialties";
    const client = "client";
    const location_key = "location";
    const [count, setCount] = useState(true)

    const handleDeleteButton = async (id, name, location, clientCurrentId, key) => {
        const deletebutton = {
            ClientId: clientCurrentId,
            LocationId: location,
            key: { [key]: id },
        }
        let response = await deleteButton(deletebutton)

        if (response.success && response.message) {
            window.toastr.success("Configuration deleted successfully.");
          } else {
              window.toastr.error(response.message ?? "Something went wrong!");
            }

        setDataSuccessfullyAdded(!dataSuccessfullyAdded)
        fetchConfigurationData(location ?? 0, clientCurrentId ?? 0)
        data.setDeleteFlag(!(data.deleteFlag))
        setCount(!count)
    }
    
      const fetchChartData = async () => {
        getMasterData()
          .then((response) => {
            setMasterData(response?.data);
          })
          .catch((error) => {
            console.log(error);
          });
      };

    const displayLocation = () => {
        return (<div className="card card-flush mb-4">
            <div className="card-header minimize">
                <div className="card-title">
                    <h4>Office Locations</h4>
                </div>
                <div className="card-toolbar mt-0">
                    <button className="btn btn-icon btn-sm btn-light-gray justify-content-center">
                        <i className="fas fa-minus" onClick={handleClose}></i>
                    </button>
                </div>
            </div>
            <div className="card-body pt-0">
                <div className="" data-kt-ecommerce-catalog-add-product="auto-options">
                    <div id="kt_ecommerce_add_product_options">
                        <div id="location">
                            <div className="applicant-fields">
                                <div className="form-group">
                                    <div data-repeater-list="kt_ecommerce_add_product_options" className="d-flex flex-column gap-3">
                                        <div data-repeater-list="kt_ecommerce_add_product_options" className="d-flex flex-column gap-3">
                                            {data?.locationData?.map((item, i) => (<div data-repeater-item="" className="form-group d-flex flex-wrap align-items-center gap-5" key={i}>
                                                <input type="text" className="form-control mw-100 w-85" name="product_option_value" value={(configurationProvider.editedData[configurationConstants.GENERAL_CONFIGURATION][configurationConstants.LOCATION_DATA] ?? {})[item.id] ?? item.name} placeholder={item.name} onChange={(e) => configurationProvider.onEditFieldChange(
                                                    configurationConstants.GENERAL_CONFIGURATION,
                                                    configurationConstants.LOCATION_DATA,
                                                    item.id,
                                                    e.target.value
                                                )}
                                                />
                                                <button type="button" data-repeater-delete="" className="btn btn-sm btn-icon btn-light-danger" onClick={() => { handleDeleteButton(item?.id, item?.client_name, item?.LocationId, clientCurrentId, location_key) }}>
                                                    <span className="svg-icon svg-icon-1"> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor" /> <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor" /> </svg> </span>
                                                </button>
                                            </div>))}
                                        </div>
                                        {(configurationProvider.submitData[configurationConstants.GENERAL_CONFIGURATION]?.locations ?? []).map((e, i) => {
                                            return <div id="kt_ecommerce_add_product_options">
                                                <div id="location">
                                                    <div className="applicant-fields">
                                                        <div className="form-group">
                                                            <div data-repeater-list="kt_ecommerce_add_product_options" className="d-flex flex-column gap-3">
                                                                <div data-repeater-item="" className="form-group d-flex flex-wrap align-items-center gap-5" >
                                                                    <input type="text" className="form-control mw-100 w-85" onChange={(e) => configurationProvider.onFieldChange(
                                                                        configurationConstants.GENERAL_CONFIGURATION,
                                                                        configurationConstants.LOCATION_DATA,
                                                                        i, e.target.value
                                                                    )} />
                                                                    <button type="button" data-repeater-delete="" className="btn btn-sm btn-icon btn-light-danger" onClick={() => configurationProvider.onDeleteClick(
                                                                        configurationConstants.GENERAL_CONFIGURATION,
                                                                        configurationConstants.LOCATION_DATA,
                                                                        i
                                                                    )}>
                                                                        <span className="svg-icon svg-icon-1"> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor" /> <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor" /> </svg> </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group mt-5">
                            <button type="button" data-repeater-create="" className="btn btn-sm btn-light" onClick={() => configurationProvider.onAddClick(
                                configurationConstants.GENERAL_CONFIGURATION,
                                configurationConstants.LOCATION_DATA
                            )}>
                                <span className="svg-icon svg-icon-2"> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor" /> <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor" /> </svg> </span>
                                Add another</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }

    const displayClient = () => {
        return (<div className="card card-flush mb-4">
            <div className="card-header minimize">
                <div className="card-title">
                    <h4>Clients</h4>
                </div>
                <div className="card-toolbar mt-0">
                    <button className="btn btn-icon btn-sm btn-light-gray justify-content-center">
                        <i className="fas fa-minus" onClick={handleClose}></i>
                    </button>
                </div>
            </div>
            <div className="card-body pt-0">
                <div className="" data-kt-ecommerce-catalog-add-product="auto-options">
                    <div id="kt_ecommerce_add_product_options">
                        <div id="client">
                            <div className="applicant-fields">
                                <div className="form-group">
                                    <div data-repeater-list="kt_ecommerce_add_product_options" className="d-flex flex-column gap-3">
                                        <div data-repeater-list="kt_ecommerce_add_product_options" className="d-flex flex-column gap-3">
                                            {masterData?.clients?.map((item, i) => (<div data-repeater-item="" className="form-group d-flex flex-wrap align-items-center gap-5" key={i}>
                                                <input type="text" className="form-control mw-100 w-85" name="product_option_value" value={(configurationProvider.editedData[configurationConstants.GENERAL_CONFIGURATION][configurationConstants.CLIENT_DATA] ?? {})[item.id] ?? item.name} placeholder={item.client_name} onChange={(e) => configurationProvider.onEditFieldChange(
                                                    configurationConstants.GENERAL_CONFIGURATION,
                                                    configurationConstants.CLIENT_DATA,
                                                    item.id,
                                                    e.target.value
                                                )}
                                                />
                                                <button type="button" data-repeater-delete="" className="btn btn-sm btn-icon btn-light-danger" onClick={() => { handleDeleteButton(item?.id, item?.client_name, item?.LocationId, clientCurrentId, client) }}>
                                                    <span className="svg-icon svg-icon-1"> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor" /> <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor" /> </svg> </span>
                                                </button>
                                            </div>))}
                                        </div>
                                        {(configurationProvider.submitData[configurationConstants.GENERAL_CONFIGURATION]?.client ?? []).map((e, i) => {
                                            return <div id="kt_ecommerce_add_product_options">
                                                <div id="client">
                                                    <div className="applicant-fields">
                                                        <div className="form-group">
                                                            <div data-repeater-list="kt_ecommerce_add_product_options" className="d-flex flex-column gap-3">
                                                                <div data-repeater-item="" className="form-group d-flex flex-wrap align-items-center gap-5" >
                                                                    <input type="text" className="form-control mw-100 w-85" onChange={(e) => configurationProvider.onFieldChange(
                                                                        configurationConstants.GENERAL_CONFIGURATION,
                                                                        configurationConstants.CLIENT_DATA,
                                                                        i, e.target.value
                                                                    )} />
                                                                    <button type="button" data-repeater-delete="" className="btn btn-sm btn-icon btn-light-danger" onClick={() => configurationProvider.onDeleteClick(
                                                                        configurationConstants.GENERAL_CONFIGURATION,
                                                                        configurationConstants.CLIENT_DATA,
                                                                        i
                                                                    )}>
                                                                        <span className="svg-icon svg-icon-1"> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor" /> <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor" /> </svg> </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group mt-5">
                            <button type="button" data-repeater-create="" className="btn btn-sm btn-light" onClick={() => configurationProvider.onAddClick(
                                configurationConstants.GENERAL_CONFIGURATION,
                                configurationConstants.CLIENT_DATA
                            )}>
                                <span className="svg-icon svg-icon-2"> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor" /> <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor" /> </svg> </span>
                                Add another</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }

    const displayProcess = () => {
        return (<div className="card card-flush mb-4">
            <div className="card-header minimize">
                <div className="card-title">
                    <h4>Project Types</h4>
                </div>
                <div className="card-toolbar mt-0">
                    <button className="btn btn-icon btn-sm btn-light-gray justify-content-center">
                        <i className="fas fa-minus" onClick={handleClose}></i>
                    </button>
                </div>
            </div>
            <div className="card-body pt-0">
                <div className="" data-kt-ecommerce-catalog-add-product="auto-options">
                    <div id="kt_ecommerce_add_product_options">
                        <div id="process">
                            <div className="applicant-fields">
                                <div className="form-group">
                                    <div data-repeater-list="kt_ecommerce_add_product_options" className="d-flex flex-column gap-3">
                                        <div data-repeater-list="kt_ecommerce_add_product_options" className="d-flex flex-column gap-3">
                                            {data.configrationprocesses.map((item, i) => (<div data-repeater-item="" className="form-group d-flex flex-wrap align-items-center gap-5" key={i}>
                                                <input type="text" className="form-control mw-100 w-85" name="product_option_value" value={(configurationProvider.editedData[configurationConstants.GENERAL_CONFIGURATION][configurationConstants.PROCESS_DATA] ?? {})[item.id] ?? item.proc_name} placeholder={item.proc_name} onChange={(e) => configurationProvider.onEditFieldChange(
                                                    configurationConstants.GENERAL_CONFIGURATION,
                                                    configurationConstants.PROCESS_DATA,
                                                    item.id,
                                                    e.target.value
                                                )}
                                                />
                                                <button type="button" data-repeater-delete="" className="btn btn-sm btn-icon btn-light-danger" onClick={() => { handleDeleteButton(item?.id, item?.proc_name, item?.LocationId, clientCurrentId, processes) }}>
                                                    <span className="svg-icon svg-icon-1"> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor" /> <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor" /> </svg> </span>
                                                </button>
                                            </div>))}
                                        </div>
                                        {(configurationProvider.submitData[configurationConstants.GENERAL_CONFIGURATION]?.processes ?? []).map((e, i) => {
                                            return <div id="kt_ecommerce_add_product_options">
                                                <div id="process">
                                                    <div className="applicant-fields">
                                                        <div className="form-group">
                                                            <div data-repeater-list="kt_ecommerce_add_product_options" className="d-flex flex-column gap-3">
                                                                <div data-repeater-item="" className="form-group d-flex flex-wrap align-items-center gap-5" >
                                                                    <input type="text" className="form-control mw-100 w-85" onChange={(e) => configurationProvider.onFieldChange(
                                                                        configurationConstants.GENERAL_CONFIGURATION,
                                                                        configurationConstants.PROCESS_DATA,
                                                                        i, e.target.value
                                                                    )} />
                                                                    <button type="button" data-repeater-delete="" className="btn btn-sm btn-icon btn-light-danger" onClick={() => configurationProvider.onDeleteClick(
                                                                        configurationConstants.GENERAL_CONFIGURATION,
                                                                        configurationConstants.PROCESS_DATA,
                                                                        i
                                                                    )}>
                                                                        <span className="svg-icon svg-icon-1"> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor" /> <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor" /> </svg> </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group mt-5">
                            <button type="button" data-repeater-create="" className="btn btn-sm btn-light" onClick={() => configurationProvider.onAddClick(
                                configurationConstants.GENERAL_CONFIGURATION,
                                configurationConstants.PROCESS_DATA
                            )}>
                                <span className="svg-icon svg-icon-2"> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor" /> <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor" /> </svg> </span>
                                Add another</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
  
    const renderTechStack = () => {
        return masterData?.tech_stack?.map((data) => ({
          label: data?.group_name,
          value: data?.id,
        }));
    };

    let event_value;  

    const filterSpecialityOnTheBasisOfTechStackCategories = (event) => {
        event_value = event?.value;

        setTechStackCategories(event_value)
    }

    useEffect(() => {
        fetchChartData();
      }, [dataSuccessfullyAdded]);

    return (
        <>
            <div className="tab-pane fade show active" id="general" role="tabpanel">
                <div className="d-flex flex-column gap-7 gap-lg-5">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            {displayLocation()}
                            {displayProcess()}
                        </div>     
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            {displayClient()}
                        </div>    
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div className="card card-flush mb-4">
                                <div className="card-header minimize">
                                    <div className="card-title">
                                        <h4>Technologies</h4>
                                    </div>
                                    <div className="card-toolbar mt-0">

                                        <button className="btn btn-icon btn-sm btn-light-gray justify-content-center">
                                            <i className="fas fa-minus" onClick={handleClose}></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body pt-0 mt-5">
                                <Select className="mb-5" options={renderTechStack()} isMulti={false} onChange={(e) => filterSpecialityOnTheBasisOfTechStackCategories(e)}/>
                                    <div className="" data-kt-ecommerce-catalog-add-product="auto-options">
                                        <div id="kt_ecommerce_add_product_options" >
                                            <div id="process">
                                                <div className="applicant-fields">
                                                    <div className="form-group">
                                                        <div data-repeater-list="kt_ecommerce_add_product_options" className="d-flex flex-column gap-3">
                                                            <div data-repeater-list="kt_ecommerce_add_product_options" className="d-flex flex-column gap-3">
                                                                {masterData?.speciality_by_techstack_category.filter(obj => obj.group_id === techStackCategories)?.map((item, i) => (<div data-repeater-item="" className="form-group d-flex flex-wrap align-items-center gap-5" key={i}>
                                                                    <input type="text" className="form-control mw-100 w-85" name="product_option_value"
                                                                        value={(configurationProvider.editedData[configurationConstants.GENERAL_CONFIGURATION][configurationConstants.SPECIALITY_DATA] ?? {})[item.id] ?? item.spec_name} placeholder={item.spec_name} onChange={(e) => configurationProvider.onEditFieldChange(
                                                                            configurationConstants.GENERAL_CONFIGURATION,
                                                                            configurationConstants.SPECIALITY_DATA,
                                                                            item?.id,
                                                                            e.target.value
                                                                        )}
                                                                    />
                                                                    <button type="button" data-repeater-delete="" className="btn btn-sm btn-icon btn-light-danger" onClick={() => handleDeleteButton(item?.id, item?.spec_name, item?.LocationId, clientCurrentId, specialties)}>
                                                                        <span className="svg-icon svg-icon-1"> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor" /> <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor" /> </svg> </span>
                                                                    </button>
                                                                </div>))}
                                                            </div>
                                                            {(configurationProvider.submitData[configurationConstants.GENERAL_CONFIGURATION]?.specialties ?? []).map((e, i) => {
                                                                return <div id="kt_ecommerce_add_product_options">
                                                                    <div id="process">
                                                                        <div className="applicant-fields">
                                                                            <div className="form-group">
                                                                                <div data-repeater-list="kt_ecommerce_add_product_options" className="d-flex flex-column gap-3">
                                                                                    <div data-repeater-item="" className="form-group d-flex flex-wrap align-items-center gap-5" >
                                                                                        <input type="text" className="form-control mw-100 w-85" onChange={(e) => configurationProvider.onFieldChange(
                                                                                            configurationConstants.GENERAL_CONFIGURATION,
                                                                                            configurationConstants.SPECIALITY_DATA,
                                                                                            i, e.target.value
                                                                                        )} />
                                                                                        <button type="button" data-repeater-delete="" className="btn btn-sm btn-icon btn-light-danger" onClick={() => configurationProvider.onDeleteClick(
                                                                                            configurationConstants.GENERAL_CONFIGURATION,
                                                                                            configurationConstants.SPECIALITY_DATA,
                                                                                            i
                                                                                        )}>
                                                                                            <span className="svg-icon svg-icon-1"> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor" /> <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor" /> </svg> </span>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mt-5">
                                                <button type="button" data-repeater-create="" className="btn btn-sm btn-light"
                                                    onClick={() => configurationProvider.onAddClick(
                                                        configurationConstants.GENERAL_CONFIGURATION,
                                                        configurationConstants.SPECIALITY_DATA,
                                                    )}
                                                >
                                                    <span className="svg-icon svg-icon-2"> <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor" /> <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor" /> </svg> </span>
                                                    Add another</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>               
                    </div>
                </div>
            </div>
        </>
    );
};
export default GeneralConfiguration;