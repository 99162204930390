import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { getUserDetail } from "../../../services/userService";
import { getLogHourDetails, deleteLogHours } from "../../../services/chartService";
import Swal from "sweetalert2";
import TimesheetViewLogHoursModal from "./TimeSheetViewLogHoursModal";
const moment = require("moment");

function ReportsModal({ setShow, show, projectId, modalUserId, date, handleRerenderdata, masterData }) {
  const handleClose = () => setShow(false);
  const [loggedInUser, setLoggedInUser] = useState({});
  const [displayData, setDisplayData] = useState({});
  const [modalValues, setModalValues] = useState();
  const [success, setSuccess] = useState(false);
  const [showModalBox, setShowModalBox] = useState(false);

  useEffect(() => {
    const fetchLogHourDetails = async () => {
      if (modalUserId && projectId && date) {
        const logHours = await getLogHourDetails(modalUserId, projectId, date);
        setDisplayData(logHours);
      }
    };
    fetchLogHourDetails();
    fetchLoggedInUserDetails();
    // eslint-disable-next-line
  }, []);

  const fetchLoggedInUserDetails = () => {
    getUserDetail()
      .then((response) => {
        if (response.success === true) {
          setLoggedInUser({ id: response?.data?.id, name: `${response?.data?.first_name} ${response?.data?.last_name}` });
        } else {
          window.toastr.error(response.message);
        }
      })
      .catch((error) => {
        window.toastr.error(error);
      });
  };

  async function handleShowDeleteLog(log_id, id) {
    Swal.fire({
      text: "Are you sure you would like to delete this Log?",
      icon: "warning",
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-active-light",
      },
    }).then(async function (result) {
      if (result?.value === true) {
        deleteLogHours(id, log_id).then((response) => {
          if (response.success) {
            window.toastr.success("Log Hours deleted successfully");
            setSuccess(!success);
            handleRerenderdata(response);
            handleClose();
          } else {
            window.toastr.error(response.message);
          }
        });
      }
    });
  }

  const handleModalClose = () => {
    Swal.fire({
      text: "Are you sure you would like to cancel?",
      icon: "warning",
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: "Yes, cancel it!",
      cancelButtonText: "No, return",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-active-light",
      },
    })
      .then((result) => {
        if (result.value) {
          setShowModalBox(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            View log details
            <br />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_table_users" style={{ minWidth: "750px", tableLayout: "fixed", borderCollapse: "collapse" }}>
              <thead>
                <tr className="text-start text-dark fw-bold fs-7 text-uppercase gs-0">
                  <th className="min-w-50px w-25">User</th>
                  <th className="min-w-50px w-25">Date</th>
                  <th className="min-w-50px w-25">Task ID</th>
                  <th className="min-w-90px w-100">Description</th>
                  <th className="min-w-50px w-25">Hours</th>
                  <th className="min-w-50px w-25">Action</th>
                </tr>
              </thead>

              <tbody className="text-gray-600 fw-semibold">
                {displayData?.data?.logDetailsForUser?.map((response, index) => (
                  <tr key={index}>
                    <td>{response?.user_name}</td>
                    <td>{moment(response?.date, "YYYY-MM-DD").format("DD-MMM-YYYY")}</td>
                    <td>{response?.task_no}</td>
                    <td className="report-log-hours min-w-100px">{response?.description}</td>
                    <td>{response?.hours}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-icon btn-light-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          setModalValues({ id: response?.id, assignee_name: response?.user_name, assignee_id: response?.assignee_id, date: moment(response?.date).format("DD-MM-YYYY"), hours: response?.hours, description: response?.description, task_id: response?.task_id, task_no: response?.task_no, tag: { id: response?.tag_id, label: response?.tag_name, value: "1" }, modal_type: parseInt(response?.assignee_id) === parseInt(loggedInUser.id) ? "update" : "view" });
                          setShowModalBox(true);
                        }}
                      >
                        {" "}
                        <i className={`${parseInt(response?.assignee_id) === parseInt(loggedInUser.id) ? "fas fa-pen" : "far fa-eye"}`} />{" "}
                      </button>
                      {response?.assignee_id === loggedInUser.id ? (
                        <button className="btn btn-sm btn-icon btn-light-primary delete-row mobile-delete-row ms-1" onClick={() => handleShowDeleteLog(response?.id, response?.assignee_id)}>
                          <i className="fa fa-trash" title="Delete Log Hours"></i>
                        </button>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pe-5" style={{ textAlign: "right", padding: "10px", fontWeight: "bold" }}>
            Total <span className="ps-2">{displayData?.data?.total_hours ?? 0}</span>
          </div>
          {showModalBox && <TimesheetViewLogHoursModal handleModalClose={handleModalClose} setShowModalBox={setShowModalBox} setSuccess={setSuccess} success={success} showModalBox={showModalBox} taskDetails={displayData?.data?.logDetailsForUser} modalValues={modalValues} handleRerenderdata={handleRerenderdata} setShow={setShow} masterData={masterData} />}
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ReportsModal;
