import { axios } from "../utils/axios";
const baseUrl = process.env.REACT_APP_API_HOST;

export const filterWithGetAllTimesheet = async(postData) =>{
    let jsonData;
    try {
      let res = await axios.post(`${baseUrl}/reports/timesheet/log-hours`, postData);
      if (res?.success) {
        // window.toastr.success(res.message);
      }
      jsonData = res;
    } catch (e) {
      if (e?.response) {
        jsonData = e.response.data;
        window.toastr.error(jsonData.message);
      } else {
        jsonData = {};
      }
    }
    return jsonData;
  }

  export const exportAttendance = async(postData) =>{
    let jsonData;
    try {
      let res = await axios.post(`${baseUrl}/reports/timesheet/attendence`, postData, {  responseType: "arraybuffer" });
      if (res?.success) {
        window.toastr.success(res.message);
      }
      jsonData = res;
    } catch (e) {
      if (e?.response) {
        jsonData = e.response.data;
        window.toastr.error(jsonData.message);
      } else {
        jsonData = {};
      }
    }
    return jsonData;
  }

  export const fetchProjectForTimesheet = async () => {
    let jsonData;
    try {
      let res;
      res = await axios.get(`${baseUrl}/reports/projects`);
      jsonData = res.data;
    } catch (e) {}
    return jsonData;
  };

  export const fetchUserForTimesheet = async () => {
    let jsonData;
    try {
      let res;
      res = await axios.get(`${baseUrl}/reports/project-members`);
      jsonData = res.data;
    } catch (e) {}
    return jsonData;
  };

  export const fetchTaskForTimesheet = async (projectId) => {
    let jsonData;
    try {
      let res;
      res = await axios.get(`${baseUrl}/reports/assigned-tasks?project=${projectId}`);
      jsonData = res.data;
    } catch (e) {}
    return jsonData;
  };