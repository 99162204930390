import FullCalendar from "@fullcalendar/react";
import React, { useRef } from "react";
import daygridPlugin from "@fullcalendar/daygrid";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReplyLogHourNotification from "../Layout/ReplyLogHourNotification";
import moment from "moment";

const CoderAttendance = ({ events, userId, handleLeaveRequest, role, rollId, coderData}) => {
  const [currentMonth, setCurrentMonth] = useState(moment().format("MM"));
  const [currentYear, setCurrentYear] = useState(moment().format("YYYY"));
  const [showMainModalBox, setShowMainModalBox] = useState(false);
  const [userName, setUserName] = useState("")
  const [userImage, setUserImage] = useState("")
  const [dates, setDate] = useState("")
  const [notificationDesciption,setNotificationDescription] = useState("")
  const [notificationId,setNotificationId] = useState("")


  const navigateStateGlobal =  useLocation();
  const handleNotification = (date, notification_description, notification_id) => {
    setDate(date)
    setNotificationDescription(notification_description)
    setNotificationId(notification_id)
    setShowMainModalBox(!showMainModalBox)
  }
  
  const fetchCalenderDetails = async () => {
  };

  useEffect(() => {
    if (userId !== undefined && currentMonth !== undefined && currentYear !== undefined) {
      fetchCalenderDetails();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, currentMonth, currentYear, handleLeaveRequest,navigateStateGlobal]);

  const calendarRef = useRef(null);
  const handlePrevClick = async () => {
    calendarRef.current.getApi().prev();
    setCurrentMonth(moment(calendarRef.current.getApi().currentData.dateProfile.currentDate).format("MM"));
    setCurrentYear(moment(calendarRef.current.getApi().currentData.dateProfile.currentDate).format("YYYY"));
  };

  const handleNextClick = async () => {
    calendarRef.current.getApi().next();
    setCurrentMonth(moment(calendarRef.current.getApi().currentData.dateProfile.currentDate).format("MM"));
    setCurrentYear(moment(calendarRef.current.getApi().currentData.dateProfile.currentDate).format("YYYY"));
  };
  
  const handleTodayClick = () => {
    const today = moment().format('YYYY-MM-DD');
    const calendarApi = calendarRef.current.getApi();
    calendarApi.gotoDate(today);
    setCurrentMonth(moment(calendarRef.current.getApi().currentData.dateProfileGenerator.nowDate).format("MM"));
    setCurrentYear(moment(calendarRef.current.getApi().currentData.dateProfileGenerator.nowDate).format("YYYY"));
  };

  useEffect(()=>{
    setUserName(coderData?.first_name + " "+ coderData?.last_name)
    setUserImage(coderData?.image_url)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="flex-lg-row-fluid ms-lg-8">
        <div className="card  mb-6 mb-xl-9">
          <div className="card-header border-0 mt-6" style={{ display: "block" }}>
            <div>
              <div className="row">
                <div className="col-lg-9">
                  <h2 className="d-flex align-items-center pt-3"> Calendar</h2>
                </div>
                {parseInt(localStorage.getItem("RoleId")) === userId && (
                  <>
                    <div className="col-lg-3 d-flex justify-content-end mb-md-5">
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="card-body p-9 pt-0 management-calendar">
            <div className="row g-3">
              <div className="col-lg-7 col-md-12">
                <div className="p-card">
                  <FullCalendar
                    ref={calendarRef}
                    displayEventTime={false}
                    plugins={[daygridPlugin, bootstrapPlugin]}
                    initialView="dayGridMonth"
                    events={events}
                    
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    eventOverlap = {false}
                    customButtons={{
                      prev: {
                        text: "Prev",
                        click: handlePrevClick,
                      },
                      next: {
                        text: "Next",
                        click: handleNextClick,
                      },
                      today: {
                        text: "Today",
                        click: handleTodayClick,
                      },
                    }}
                  />
                </div>
              </div>
              <div className="col-md-5 pt-3" style={{height: '60vh', overflow: 'auto'}}>
                <div className="px-3">
                  <h3 className="d-flex align-items-center pt-3">Log hour notification</h3>
                  <ul className="list-unstyled mt-3 scrollbar management-calendar-events" id="management-calendar-events">
                  {coderData?.userMissedLogHour?.map(res =>
                      <li className="border-top pt-3 mb-3 pb-1 cursor-pointer" data-calendar-events="" key={res?.notification_id} onClick={()=>{handleNotification(res?.date, res?.notification_description, res?.notification_id)}}>
                        <div className="border-start border-3 border-primary ps-3 mt-1">
                          <h6 className="mb-1 fw-semi-bold text-700">{res?.notification_description}</h6>
                          <p className="fs--2 text-600 mb-0">{res?.date}</p>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>  
          </div>   
        </div>
      </div>
      {showMainModalBox && <ReplyLogHourNotification showMainModalBox={showMainModalBox} setShowMainModalBox={setShowMainModalBox}  notification={notificationDesciption} date={dates} userName={userName} imageUrl={userImage} userId={userId} notificationId={notificationId}/>}
    </>
  );
};
export default CoderAttendance;
