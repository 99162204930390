import { Modal, Container } from "react-bootstrap";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import "./ManagerUsers.css";
import { registerUser, fetchMasterOnRole } from "../../../services/managerService";
import encrypt from "../../../utils/encrypt";

const AddNewUserModal = ({ masterData, changeParentList}) => {
  const [desigList, setDesigList] = useState([]);
  const [showModalBox, setShowModalBox] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleRole = async (event) => {
    if (event.target.value !== "") {
      let response = await fetchMasterOnRole(event.target.value);
      setDesigList(response.designations);
    }
    setValue("RoleId", event.target.value);
    if (errors.RoleId) {
      setValue("RoleId", event.target.value, { shouldValidate: true });
    }
  };

  const renderLocations = () => {
    return masterData?.locations?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };

  const renderSpecialties = () => {
    return masterData?.specialties?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };

  const renderClients = () => {
    return masterData?.clients?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm();

  const watchPassword = watch("password", "");
  const registerOptions = {
    first_name: { required: "Required", pattern: { value: /^\S[A-Za-zÀ-ȕ0-9 ]/, message: "Invalid input" } },
    last_name: { required: "Required", pattern: { value: /^\S[A-Za-zÀ-ȕ0-9 ]/, message: "Invalid input" } },
    employee_id: { required: "Required", pattern: { value: /^\S[A-Za-zÀ-ȕ0-9 ]/, message: "Invalid input" } },
    email : { required: "Required", pattern: { value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, message : "Invalid input" } },
    password: {
      required: "Required",
      pattern: {
        value: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/,
        message:
          "Password must be between 8 and 20 characters and contain at least one letter, one digit, and one special character.",
      },
    },
    confirm_password: {
      required: "Required",
      validate: (value) =>
        value === watchPassword || "Passwords do not match",
    },
    role_id: { required: "Required" },
    Specialties: { required: "Required" },
    designation_id: { required: "Required" },
  };

  const handleRegistration = async (data) => {
    let joiningDate = "";
    let dateOfBirth = "";
    if (data?.joining_date) {
    joiningDate = data?.joining_date;
    }
    if (data?.date_of_birth) {
      dateOfBirth = data?.date_of_birth;
    }
    let object = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      employee_id: data?.employee_id,
      email: data?.email,
      password: encrypt(data?.password),
      role_id: data?.role_id,
      date_of_birth: dateOfBirth,
      joining_date: joiningDate,
      Specialties: data?.Specialties?.map((item) => item.value),
      location_id: data?.location_id?.length !== 0 ? data?.location_id?.map((item) => item.value) : "",
      client_id: data?.client_id?.length !== 0 ? data?.client_id?.map((item) => item.value) : "",
      designation_id: data?.designation_id,
    };
    let response = await registerUser(object);
    if (response?.success === true) {
      window.toastr.success("User successfully added");
      handleResetGrantModal();
      changeParentList(true);
    } else {
      handleResetGrantModal();
      setShowModalBox(false);
      window.toastr.error(response?.message);
    }
  };

  const handleResetGrantModal = () => {
    reset({
      first_name: null,
      last_name: null,
      employee_id: null,
      role_id: null,
      date_of_birth: null,
      joining_date: null,
      Specialties: null,
      location_id: null,
      client_id: null,
      designation_id: null,
      email: null,
      password: null,
      confirm_password: null,
    });
    setShowModalBox(false);
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        onClick={(e) => {
          setShowModalBox(true);
        }}
      >
        <span className="svg-icon svg-icon-2">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor" />
            <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor" />
          </svg>
        </span>
        Add User
      </button>
      <Container>
        <Modal show={showModalBox}>
          <Modal.Header>
            <h2 className="fw-bold">Add User</h2>
            <button
              className="btn btn-icon btn-sm btn-active-icon-primary"
              onClick={(e) => {
                handleResetGrantModal();
                setShowModalBox(false);
              }}
            >
              <i className="fas fa-times"></i>
            </button>
          </Modal.Header>
          <div className="modal-body scroll-y my-5">
            <form onSubmit={handleSubmit(handleRegistration)}>
              <div className="row mb-4">
                <div className="col-6">
                  <label className="required form-label">First name</label>
                  <input className="form-control" type="text" name="first_name" placeholder="" minLength={1} maxLength={256} {...register("first_name", registerOptions.first_name)} />
                  <small className="text-danger">{errors?.first_name && errors.first_name.message}</small>
                </div>
                <div className="col-6">
                  <label className="required form-label">Last name</label>
                  <input className="form-control" type="text" name="last_name" placeholder="" minLength={1} maxLength={256} {...register("last_name", registerOptions.last_name)} />
                  <small className="text-danger">{errors?.last_name && errors.last_name.message}</small>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-3">
                  <label className="required form-label">ID </label>
                  <input className="form-control" type="text" name="employee_id" placeholder="" minLength={1} maxLength={6} {...register("employee_id", registerOptions.employee_id)} />
                  <small className="text-danger">{errors?.employee_id && errors.employee_id.message}</small>
                </div>
                
                <div className="col-9">
                  <label className="required form-label">Email</label>
                  <input className="form-control" type="text" name="email" placeholder="" {...register("email", registerOptions.email)} />
                  <small className="text-danger">{errors?.email && errors.email.message}</small>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-6">
                  <label className="required form-label">Password</label>
                  <div className="password-input-container">
                  <input
                    className="form-control password-input"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder=""
                    minLength={8}
                    maxLength={20}
                    {...register("password", registerOptions.password)}
                  />
                  <span className="password-toggle-icon" onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <i className="fas fa-eye-slash"></i>
                    ) : (
                      <i className="fas fa-eye"></i>
                    )}
                  </span>
                </div>
                <small className="text-danger">
                  {errors?.password && errors.password.message}
                </small>
                </div>
                <div className="col-6">
                  <label className="required form-label">Confirm Password</label>
                  <div className="password-input-container">
                  <input
                    className="form-control password-input"
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirm_password"
                    placeholder=""
                    minLength={8}
                    maxLength={20}
                    {...register("confirm_password", registerOptions.confirm_password)}
                  />
                  <span
                    className="password-toggle-icon"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <i className="fas fa-eye-slash"></i>
                    ) : (
                      <i className="fas fa-eye"></i>
                    )}
                  </span>
                </div>
                <small className="text-danger">
                  {errors?.confirm_password && errors.confirm_password.message}
                </small>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12">
                  <label className="required form-label">Technology </label>
                  <Controller name="Specialties" control={control} rules={{ required: "Required" }} render={({ field }) => <Select {...field} isMulti options={renderSpecialties()} />} />
                  <small className="text-danger">{errors?.Specialties && errors.Specialties.message}</small>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-6">
                  <label className="required form-label">Role </label>
                  <select
                    className="form-select mb-2"
                    data-control="select2"
                    data-hide-search="true"
                    name="role_id"
                    {...register("role_id", registerOptions.role_id)}
                    onChange={(registerOptions) => {
                      handleRole(registerOptions);
                    }}
                  >
                    <option value="">---</option>
                    {masterData?.roles?.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  <small className="text-danger">{errors?.role_id && errors.role_id.message}</small>
                </div>
                <div className="col-6">
                  <label className="required form-label fs-6 fw-semibold">Designation</label>
                  <select className="form-select mb-2" data-control="select2" data-hide-search="true" name="designation_id" {...register("designation_id", registerOptions.designation_id)}>
                    <option value="">---</option>
                    {desigList.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                  <small className="text-danger">{errors?.designation_id && errors.designation_id.message}</small>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-6">
                  <label className="form-label fs-6 fw-semibold">Client</label>
                  <Controller name="client_id" control={control} render={({ field }) => <Select {...field} isMulti options={renderClients()} />} />
                  <small className="text-danger">{errors?.client_id && errors.client_id.message}</small>
                </div>
                <div className="col-6">
                  <div className="mb-4">
                    <label className="form-label fs-6 fw-semibold">Locations</label>
                    <Controller name="location_id" control={control} render={({ field }) => <Select {...field} isMulti options={renderLocations()} />} />
                    <small className="text-danger">{errors?.location_id && errors.location_id.message}</small>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-6">
                  <label className="form-label">Date of birth</label>
                  <Controller name="date_of_birth" control={control} defaultValue="" render={({ field: { onChange, ...fieldProps } }) => <Flatpickr className="form-control" placeholder="" options={{ dateFormat: "d-m-Y", animate: true }} onChange={(dates, currentdateString) => onChange(currentdateString)} name="date_of_birth" />} />
                </div>
                <div className="col-6">
                  <label className="fs-6 fw-semibold mb-2">
                    <span>Date of joining</span>
                  </label>
                  <Controller name="joining_date" control={control} defaultValue="" render={({ field: { onChange, ...fieldProps } }) => <Flatpickr options={{ dateFormat: "d-m-Y", animate: true }} className="form-control" placeholder="" onChange={(dates, currentdateString) => onChange(currentdateString)} name="joining_date" />} />
                </div>
              </div>
              <div className="text-center pt-8">
                <button type="button" className="btn btn-light me-3" onClick={handleResetGrantModal}>
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  <span className="indicator-label">Save</span>
                  <span className="indicator-progress">
                    Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </Container>
    </>
  );
};
export default AddNewUserModal;
