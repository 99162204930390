import { Container, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { addPlannedEstimate } from "../../../services/managerWorkListService";
const EstimationHoursModal = ({ showModalBox, setShowModalBox, masterData, worklistId, handleRerenderdata}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const registerOptions = {
    estimation_hours: { required: "Required", pattern: { value: /^\S/, message: "Invalid input" }, maxLength: { value: 250, message: "Exceeded max limit" } },
    tag_id: { required: "Required" },
  };
  const onSave = async (data) => {
    let obj = {};
    obj.project_id = worklistId;
    obj.estimation_hours = Number(data.estimation_hours);
    obj.tag_id = Number(data.tag_id);
    const response = await addPlannedEstimate(obj);
    if(response?.success) {
      setShowModalBox(false);
      resetCard();
      handleRerenderdata(response);
    }
   };
  const resetCard = () => {
    reset({
      estimation_hours: "",
      tag_id: "",
    });
  };

  return (
    <>
      <Container>
        <Modal show={showModalBox} centered>
          <div>
            <div>
              <Modal.Header className="modal-header py-4">
                <h2 className="fw-bold">Add Planned Hours</h2>
                <Modal.Title
                  className="btn btn-icon btn-sm btn-active-icon-primary"
                  onClick={() => {
                    setShowModalBox(false);
                    resetCard();
                  }}
                >
                  <span className="svg-icon svg-icon-1">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                      <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                    </svg>
                  </span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="modal-body my-5">
                <form onSubmit={handleSubmit(onSave)}>
                  <div style={{ maxHeight: "510px", overflowY: "auto", overflowX: "hidden" }}>
                    <div className="row mb-4">
                      <div className="col-lg-7">
                        <label className="required form-label">Tag </label>
                        <select className="form-select mb-2" data-control="select2" data-hide-search="true" name="tag_id" {...register("tag_id", registerOptions.tag_id)}>
                          <option value="">---</option>
                          {masterData?.tags?.map((option) => (
                            <option key={option?.id} value={option?.id}>
                              {option?.label}
                            </option>
                          ))}
                        </select>
                        <small className="text-danger">{errors.tag_id && errors.tag_id.message}</small>
                      </div>
                      <div className="col-lg-5">
                        <OverlayTrigger placement={"top"} overlay={<Tooltip> Formats supported: 2, 2.5 </Tooltip>}>
                          <label className="required form-label fs-6 fw-semibold mb-2">
                            Estimation Hours <i className="fas fa-exclamation-circle ms-1 fs-6"></i>
                          </label>
                        </OverlayTrigger>

                        <input className="form-control" type="text" name="estimation_hours" placeholder="" {...register("estimation_hours", registerOptions.estimation_hours)} />
                        <small className="text-danger">{errors?.estimation_hours && errors.estimation_hours.message}</small>
                      </div>
                    </div>

                    <div></div>
                  </div>
                  <div className="text-center pt-8">
                    <button
                      type="reset"
                      className="btn btn-light me-3"
                      data-kt-users-modal-action="cancel"
                      onClick={(e) => {
                        setShowModalBox(false);
                        resetCard();
                        
                      }}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary me-4" id="savebutton">
                      <span className="indicator-label">Save</span>
                      <span className="indicator-progress">
                        Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  </div>
                </form>
              </Modal.Body>
            </div>
          </div>
        </Modal>
      </Container>
    </>
  );
};
export default EstimationHoursModal;
