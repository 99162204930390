import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable as Droppable } from "./helpers/StrictModeDroppable";
import { filterWithGetAllTasks, updateTaskMilestone, getProjectList } from "../../services/chartService";
import KanbanModal from "./KanbanModal";
import Select from "react-select";
import { imageIdGenerate } from "../../utils/custom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchProjectSprints } from "../../services/managerWorkListService";

const onDragEnd = async (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;
  const task = columns[source.droppableId].items[source.index];

  if (source.droppableId !== destination.droppableId) {
    const milestoneMap = {
      todo: 1,
      in_progress: 2,
      dev_done: 7,
      ready_for_qa: 3,
      qa_in_progress: 4,
      qa_done: 8,
      ready_for_client_review: 5,
      approved: 6,
    };
    const updateData = {
      milestone_id: milestoneMap[destination.droppableId] || [],
    };
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
        count: sourceItems.length ?? 0,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
        count: destItems.length ?? 0,
      },
    });
    const response = await updateTaskMilestone(task.id, updateData);
    if (response?.success === true) {
      window.toastr.success(response?.message);
    } else if (response?.success === false) {
      window.toastr.error(response?.message);
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
          count: copiedItems.length ?? 0,
        },
      });
    }
  }
};

const KanbanBoard = () => {
  const [todoTask, setTodoTask] = useState([]);
  const [inProgressTask, setInProgressTask] = useState([]);
  const [devDoneTask, setDevDoneTask] = useState([]);
  const [readyForQATask, setReadyForQATask] = useState([]);
  const [qaInProgressTask, setQAInProgressTask] = useState([]);
  const [qaDoneTask, setQADoneTask] = useState([]);
  const [readyForClientReviewTask, setReadyForClientReviewTask] = useState([]);
  const [approvedTask, setApprovedTask] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [filterList, setFilterList] = useState();
  const [disable, setDisable] = useState(true);
  const [lastSelectedProject, setLastSelectedProject] = useState();
  const [sprints, setSprints] = useState();
  const [lastSelectedSprint, setLastSelectedSprint] = useState();

  const handleFilterList = (obj) => {
    setFilterList(obj);
  };

  const [currentTodoTask, setCurrentTodoTask] = useState();
  const [currentInProgressTask, setCurrentInProgressTask] = useState();
  const [currentDevDoneTask, setCurrentDevDoneTask] = useState();
  const [currentReadyForQATask, setCurrentReadyForQATask] = useState();
  const [currentQAInProgessTask, setCurrentQAInProgessTask] = useState();
  const [currentQADoneTask, setCurrentQADoneTask] = useState();
  const [currentReadyForClientReviewTask, setCurrentReadyForClientReviewTask] = useState();
  const [currentApprovedTask, setCurrentApprovedTask] = useState();
  const [projectList, setProjectList] = useState([]);
  const [selectedProjectMembers, setSelectedProjectMembers] = useState([]);

  useEffect(() => {
    const fetchAllProjectList = async () => {
      const fetchProjectList = await getProjectList();
      setProjectList(fetchProjectList?.data || []);
    };
    fetchAllProjectList();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setColumns((prevColumns) => ({
      ...prevColumns,
      todo: {
        ...prevColumns.todo,
        items: todoTask || [],
        count: (todoTask || []).length,
      },
      in_progress: {
        ...prevColumns.in_progress,
        items: inProgressTask || [],
        count: (inProgressTask || []).length,
      },
      dev_done: {
        ...prevColumns?.dev_done,
        items: devDoneTask || [],
        count: (devDoneTask || []).length,
      },
      ready_for_qa: {
        ...prevColumns.ready_for_qa,
        items: readyForQATask || [],
        count: (readyForQATask || []).length,
      },
      qa_in_progress: {
        ...prevColumns.qa_in_progress,
        items: qaInProgressTask || [],
        count: (qaInProgressTask || []).length,
      },
      qa_done: {
        ...prevColumns.qa_done,
        items: qaDoneTask || [],
        count: (qaDoneTask || []).length,
      },
      ready_for_client_review: {
        ...prevColumns.ready_for_client_review,
        items: readyForClientReviewTask || [],
        count: (readyForClientReviewTask || []).length,
      },
      approved: {
        ...prevColumns.approved,
        items: approvedTask || [],
        count: (approvedTask || []).length,
      },
    }));
  }, [todoTask, inProgressTask, devDoneTask, readyForQATask, qaInProgressTask, qaDoneTask, readyForClientReviewTask, approvedTask]);

  useEffect(() => {
    const lastSelectedProject = localStorage.getItem("lastSelectedProject");
    if (lastSelectedProject) {
      const parsedSelectedProject = JSON.parse(localStorage.getItem("lastSelectedProject"));
      handleProjectChange(parsedSelectedProject);
    }
  }, []);

  const renderProjectOptions = () => {
    return projectList.map((project) => ({
      label: project.Project.name,
      value: project.Project.id,
      members: project.Members,
    }));
  };

  const handleProjectChange = (selectedOption) => {
    // selectedOption?.map((item) => console.log(item.value));
    setLastSelectedProject(selectedOption);
    setLastSelectedSprint("");
    localStorage.setItem("lastSelectedProject", JSON.stringify(selectedOption));
    const projectId = selectedOption?.value;
    setSelectedProjectMembers(selectedOption?.members);
    setFilterList({ worklist_id: projectId });
    setDisable(!projectId);
  };

  useEffect(() => {
    let flag = true;
    const fetchAllTasks = async (filterList) => {
      filterWithGetAllTasks(filterList)
        .then((response) => {
          if (flag) {
            handleAllTasks(response);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (filterList?.SprintId) {
      fetchAllTasks(filterList);
    } else {
      handleAllTasks({});
    }

    return () => {
      flag = false;
    };
  }, [filterList]);

  useEffect(() => {
    let flag = true;
    const fetchSprints = () => {
      const projectId = JSON.parse(localStorage.getItem("lastSelectedProject")).value;
      fetchProjectSprints(lastSelectedProject?.value ? lastSelectedProject.value : projectId)
        .then((data) => {
          if (flag) {
            setSprints(data?.data);
            if (data?.data?.active_sprints.length > 0) {
              const ACTIVE_SPRINT_AT = 0;
              // to set the current sprint by default according to selected project
              setFilterList({ SprintId: data?.data?.active_sprints[ACTIVE_SPRINT_AT]["value"] });
              handleSprintChange(data?.data?.active_sprints[ACTIVE_SPRINT_AT]);
            } else if (data?.data?.inactive_sprints.length > 0) {
              const INACTIVE_SPRINT_AT = 0;
              setFilterList({ SprintId: data?.data?.inactive_sprints[INACTIVE_SPRINT_AT]["value"] });
              // to set the current sprint by default according to selected project
              handleSprintChange(data?.data?.inactive_sprints[INACTIVE_SPRINT_AT]);
            } else if (data?.data?.active_sprints.length === 0 && data?.data?.inactive_sprints.length === 0) {
              window.toastr.error("No sprints found for this project. Please add a new sprint and activate it.");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (lastSelectedProject) {
      fetchSprints();
    }

    return () => {
      flag = false;
    };

    // eslint-disable-next-line
  }, [lastSelectedProject]);

  const renderSprintOptions = () => {
    const group = [];
    if (sprints?.active_sprints && sprints?.inactive_sprints) {
      const active = {
        label: "Active Sprints",
        options: [],
      };
      const inactive = {
        label: "Inactive Sprints",
        options: [],
      };

      for (let i in sprints) {
        if (i === "active_sprints") {
          sprints[i].forEach((sprint) => {
            active.options.push({
              label: sprint.label,
              value: sprint.value,
            });
          });
        }
        if (i === "inactive_sprints") {
          sprints[i].forEach((sprint) => {
            inactive.options.push({
              label: sprint.label,
              value: sprint.value,
            });
          });
        }
      }

      group.push(active);
      group.push(inactive);
    }
    return group;
  };

  const handleSprintChange = (selectedOption) => {
    if (selectedOption.value) {
      setLastSelectedSprint(selectedOption);
      // const projectId = JSON.parse(localStorage.getItem("lastSelectedProject")).value;
      setFilterList({ ...filterList, SprintId: selectedOption.value });
      // fetchSprintTasks(selectedOption?.value, lastSelectedProject?.value ? lastSelectedProject.value : projectId)
      //   .then((response) => {
      //     handleAllTasks(response);
      //   })
      //   .catch((err) => {
      //     console.log("error in fetching sprint associated tasks", err);
      //   });
    }
  };

  function handleAllTasks(response) {
    setTodoTask(response?.data?.todo);
    setInProgressTask(response?.data?.in_progress);
    setDevDoneTask(response?.data?.dev_done);
    setReadyForQATask(response?.data?.ready_for_qa);
    setQAInProgressTask(response?.data?.qa_in_progress);
    setQADoneTask(response?.data?.qa_done);
    setReadyForClientReviewTask(response?.data?.ready_for_client_review);
    setApprovedTask(response?.data?.approved);

    setCurrentTodoTask(response?.data?.todo);
    setCurrentInProgressTask(response?.data?.in_progress);
    setCurrentDevDoneTask(response?.data?.dev_done);
    setCurrentReadyForQATask(response?.data?.ready_for_qa);
    setCurrentQAInProgessTask(response?.data?.qa_in_progress);
    setCurrentQADoneTask(response?.data?.qa_done);
    setCurrentReadyForClientReviewTask(response?.data?.ready_for_client_review);
    setCurrentApprovedTask(response?.data?.approved);
  }

  const taskStatus = {
    todo: {
      name: "Todo",
      items: todoTask || [],
      id: 1,
      count: (todoTask || []).length ?? 0,
    },
    in_progress: {
      name: "In Progress",
      items: inProgressTask || [],
      id: 2,
      count: (inProgressTask || []).length ?? 0,
    },
    dev_done: {
      name: "Dev Done",
      items: devDoneTask || [],
      id: 7,
      count: (devDoneTask || []).length ?? 0,
    },
    ready_for_qa: {
      name: "Ready for QA",
      items: readyForQATask || [],
      id: 3,
      count: (readyForQATask || []).length ?? 0,
    },
    qa_in_progress: {
      name: "QA in Progress",
      items: qaInProgressTask || [],
      id: 4,
      count: (qaInProgressTask || []).length ?? 0,
    },
    qa_done: {
      name: "QA Done",
      items: qaDoneTask || [],
      id: 8,
      count: (qaDoneTask || []).length ?? 0,
    },
    ready_for_client_review: {
      name: "Ready for Client Review",
      item: readyForClientReviewTask || [],
      id: 5,
      count: (readyForClientReviewTask || []).length ?? 0,
    },
    approved: {
      name: "Approved",
      items: approvedTask || [],
      id: 6,
      count: (approvedTask || []).length ?? 0,
    },
  };

  const [columns, setColumns] = useState(taskStatus);

  const handleFilterChange = (e) => {
    const inputValue = e.target.value.toLowerCase(); // Convert input value to lowercase for case-insensitive matching
    // Filter tasks in each column based on the input value
    const filteredTodo = currentTodoTask ? currentTodoTask.filter((task) => (task?.name?.toLowerCase().includes(inputValue)) || task?.unique_task_no?.toLowerCase().includes(inputValue)) : [];
    const filteredInProgress = currentInProgressTask ? currentInProgressTask.filter((task) => (task?.name?.toLowerCase().includes(inputValue)) || task?.unique_task_no?.toLowerCase().includes(inputValue)) : [];
    const filteredDevDone = currentDevDoneTask ? currentDevDoneTask.filter((task) => (task?.name?.toLowerCase().includes(inputValue)) || task?.unique_task_no?.toLowerCase().includes(inputValue)) : [];
    const filteredReadyForQA = currentReadyForQATask ? currentReadyForQATask.filter((task) => (task?.name?.toLowerCase().includes(inputValue)) || task?.unique_task_no?.toLowerCase().includes(inputValue)) : [];
    const filteredQAInProgressTask = currentQAInProgessTask ? currentQAInProgessTask.filter((task) => (task?.name?.toLowerCase().includes(inputValue)) || task?.unique_task_no?.toLowerCase().includes(inputValue)) : [];
    const filteredQADone = currentQADoneTask ? currentQADoneTask.filter((task) => (task?.name?.toLowerCase().includes(inputValue)) || task?.unique_task_no?.toLowerCase().includes(inputValue)) : [];
    const filteredReadyForClientReviewTask = currentReadyForClientReviewTask ? currentReadyForClientReviewTask.filter((task) => (task?.name?.toLowerCase().includes(inputValue)) || task?.unique_task_no?.toLowerCase().includes(inputValue)) : [];
    const filteredApproved = currentApprovedTask ? currentApprovedTask.filter((task) => (task?.name?.toLowerCase().includes(inputValue)) || task?.unique_task_no?.toLowerCase().includes(inputValue)) : [];

    // Update the state for each column with the filtered tasks or reset to original when inputValue is empty
    setTodoTask(inputValue === "" ? currentTodoTask : filteredTodo);
    setInProgressTask(inputValue === "" ? currentInProgressTask : filteredInProgress);
    setDevDoneTask(inputValue === "" ? currentDevDoneTask : filteredDevDone);
    setReadyForQATask(inputValue === "" ? currentReadyForQATask : filteredReadyForQA);
    setQAInProgressTask(inputValue === "" ? currentQAInProgessTask : filteredQAInProgressTask);
    setQADoneTask(inputValue === "" ? currentQADoneTask : filteredQADone);
    setReadyForClientReviewTask(inputValue === "" ? currentReadyForClientReviewTask : filteredReadyForClientReviewTask);
    setApprovedTask(inputValue === "" ? currentApprovedTask : filteredApproved);

    // Update the filterValue state with the current input value
    setFilterValue(inputValue);
  };

  return (
    <>
      <div className="row mb-4">
        <div className="col-2 px-5 mt-8">
          <div className="input-group">
            <input type="text" className="form-control" placeholder="Search by task name..." value={filterValue} onChange={handleFilterChange} />
          </div>
        </div>
        <div className="col-3 px-5 ms-auto">
          <div className="d-flex flex-column">
            <div className="d-flex">
              <div className="input-group me-2 flex-column">
                <label className="form-label">Select Project </label>
                <Select options={renderProjectOptions()} value={lastSelectedProject} onChange={handleProjectChange} isMulti={false} placeholder="Select Project..." />
              </div>

              <div className="input-group me-4 flex-column">
                <label className="form-label"> Sprint </label>
                <Select options={renderSprintOptions()} value={lastSelectedSprint} onChange={handleSprintChange} groupBy="label" isMulti={false} placeholder="Select Sprint..." />
              </div>
            </div>
            <div className="ms-auto">
              <KanbanModal handleFilterList={handleFilterList} setLastSelectedSprint={setLastSelectedSprint} selectedProjectMembers={selectedProjectMembers} filterList={filterList} disable={disable} />
            </div>
          </div>
        </div>
      </div>
      <div className="kanban-container">
        <div style={{ display: "flex", justifyContent: "center", height: "100%" }}>
          <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
            {Object.entries(columns).map(([columnId, column], index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  key={columnId}
                >
                  <span className="fw-bold text-gray-900 fs-2">
                    {column?.name} ({column?.count})
                  </span>

                  <div className="px-5 py-5">
                    <Droppable droppableId={columnId} key={columnId}>
                      {(provided, snapshot) => {
                        return (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            data-id="_fixed_height"
                            className="kanban-board custom_scroll"
                            style={{
                              width: "250px",
                              height: "620px",
                              padding: 16,
                            }}
                          >
                            {column.items?.map((item, index) => {
                              return (
                                <Draggable key={item.id} draggableId={item?.id.toString()} index={index}>
                                  {(provided, snapshot) => {
                                    return (
                                      <div className={`kanban-item ${snapshot.isDragging ? "dragging" : ""}`} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <div className="d-flex align-items-center">
                                          <div className="d-flex flex-column align-items-start">
                                            <OverlayTrigger overlay={<Tooltip>{"Go to Task"}</Tooltip>} key={item?.id} placement={"top"}>
                                              <div
                                                className="d-flex mb-1 text-primary"
                                                onClick={() => {
                                                  localStorage.setItem("key", item.id);
                                                }}
                                              >
                                                {" "}
                                                <span className="fw-bold fs-3">#</span>
                                                <Link to={`/task/${item?.unique_task_no}`} className="fw-bold fs-4 hover">
                                                  {item?.unique_task_no}
                                                </Link>
                                              </div>
                                            </OverlayTrigger>
                                            <span className="fw-normal text-gray-700 fs-4" style={{ wordBreak: "break-all" }}>
                                              {item?.name}
                                            </span>
                                          </div>
                                        </div>
                                        <hr className="text-gray-400 p-0 " />
                                        <div className="symbol-group symbol-hover flex-nowrap justify-content-end">
                                          <OverlayTrigger overlay={<Tooltip>{item?.assignee_id ? `${item?.assigneeFirstName} ${item?.assigneeLastName}` : "Unassigned"}</Tooltip>} key={item?.id} placement={"left"}>
                                            <div className="symbol symbol-35px symbol-circle user-image" data-kt-initialized="1">
                                              <img alt="name" src={item?.assignee_id ? item?.assignee_image_url ?? `${process.env.PUBLIC_URL}/assets/media/avatars/300-${imageIdGenerate(item?.assignee_id)}.jpg` : `${process.env.PUBLIC_URL}/assets/media/avatars/blank.png`} />
                                            </div>
                                          </OverlayTrigger>
                                        </div>
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </div>
                </div>
              );
            })}
          </DragDropContext>
        </div>
      </div>
    </>
  );
};
export default KanbanBoard;
