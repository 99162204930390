// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-color {
    color:#ff0000;
    font-size: 10px;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
    border: 0 !important;
    background-color: var(--bs-body-bg);
    font-size: 1.2rem !important;
    color: var(--bs-gray-700);
    font-weight: 500;
    padding: 0.5rem 0.75rem;
    margin-right: 0.5rem;
    outline: none !important;
    border-radius: 1.15rem;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Manager/ManagerWorklistDetails.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,oBAAoB;IACpB,mCAAmC;IACnC,4BAA4B;IAC5B,yBAAyB;IACzB,gBAAgB;IAChB,uBAAuB;IACvB,oBAAoB;IACpB,wBAAwB;IACxB,sBAAsB;IACtB,gBAAgB;IAChB,qBAAqB;IACrB,wBAAwB;AAC5B","sourcesContent":[".error-color {\n    color:#ff0000;\n    font-size: 10px;\n}\n\n.flatpickr-current-month .flatpickr-monthDropdown-months {\n    border: 0 !important;\n    background-color: var(--bs-body-bg);\n    font-size: 1.2rem !important;\n    color: var(--bs-gray-700);\n    font-weight: 500;\n    padding: 0.5rem 0.75rem;\n    margin-right: 0.5rem;\n    outline: none !important;\n    border-radius: 1.15rem;\n    appearance: none;\n    -moz-appearance: none;\n    -webkit-appearance: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
