import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getActivityLogData } from "../../services/chartService";
import { imageIdGenerate } from "../../utils/custom";

function ActivityLogs({ id, data, enabled, reRenderChart }) {
  const navigateStateGlobal = useLocation();
  const [activityLogData, setActivityLogData] = useState({});
  
  const fetchActivityLogs = async () => {
    getActivityLogData(id)
      .then((response) => {
        if(response.success){
          setActivityLogData(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  useEffect(() => {
    fetchActivityLogs(id);
    // eslint-disable-next-line
  }, [enabled, navigateStateGlobal, reRenderChart]);
  
  return (
    <div className="p-0">
      <div className="col-12 mt-6">
        <div className="card card-flush h-lg-100">
          <div className="card-header py-4 minimize">
            <p className="card-title align-items-start flex-column">
              <span className="fw-bold h4">Activity Log</span>
              <span className="text-muted mt-1 fw-semibold fs-6">History of activity on this Task</span>
            </p>
            <div className="card-toolbar mt-0">
              <button className="btn btn-icon btn-sm btn-light-primary justify-content-center minimize">
                <i className="fas fa-plus" />
              </button>
            </div>
          </div>
          <div className="card-body collapse">
            <div className="m-0">
              <div className="timeline" style={{ maxHeight: "400px", overflow: "auto" }}>
                <div className="timeline-item">
                  <div className="timeline-line w-20px" />
                  <div className="timeline-icon symbol symbol-circle symbol-20px"></div>
                </div>
                {activityLogData &&
                  Object.entries(activityLogData)?.map((item, index) => (
                    <div key={index}>
                      <div className="timeline-item">
                        <div className="timeline-line w-20px" />
                        <div className="timeline-icon symbol symbol-circle symbol-20px">
                          <div className="symbol-label bg-light">
                            <i className="fa-solid fa-circle-dot fs-9" />
                          </div>
                        </div>
                        <div className="timeline-content">
                          <div className="fs-3 fw-semibold text-primary mb-0">{item[0] ?? ""}</div>
                          {item[1]?.length !== 0 &&
                            item[1]?.map((item, index) => (
                              <div key={index}>
                                <div className="separator separator-dashed my-3"></div>
                                <div className="pe-3 mb-0">
                                  <div className="overflow-auto pb-0">
                                    <div className="d-flex align-items-center">
                                      <div className="symbol symbol-35px symbol-circle user-image" data-kt-initialized="1">
                                        <img alt="assignee" src={item?.by_user?.image_url ?? `${process.env.PUBLIC_URL}/assets/media/avatars/300-${imageIdGenerate(item?.by_user?.id)}.jpg`} />
                                      </div>
                                      <div className="ms-4">
                                        <div className="me-2 fs-4">
                                          {item.Changed.length !== 0
                                            ? item.Changed.map((item, index) => (
                                                <div key={index}>
                                                  <div className="fs-4" dangerouslySetInnerHTML={{__html: item}}>
                                                  </div>
                                                </div>
                                              ))
                                            : item.activity}
                                        </div>
                                        <div className="d-flex align-items-center mt-0 fs-6">
                                          <div className="text-muted me-2 fs-7">
                                            {item.timestamp} by <span className="text-primary">{item.by_user.name}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ActivityLogs;
