import { getProjectTaskListBySprint } from "../../../services/managerWorkListService";
import { useEffect, useState } from "react";
import "../ManagerWorklistDetails.css";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import localStorageStore from "../../../utils/localStorageStore";
import role from "../../../utils/role";
import WorklistAddTaskModal from "./WorklistAddTaskModal";
import ProjectPlan from "./ProjectPlan";

function TaskList({ worklistId, success, selectedSprint: sprint_id, setSelectedSprint, dependencyForFetchWorklistProgressRerender, setDependencyForFetchWorklistProgressRerender, masterData, worklist, setSuccess, projectmemberList, configurationProvider }) {
  const [taskList, setTaskList] = useState({ sprint_name: "", tasks: [] });
  const [filteredTaskList, setFilteredTaskList] = useState({ sprint_name: "", tasks: [] });
  const [showModalBox, setShowModalBox] = useState(false);
  const navigate = useNavigate();
  const CloseTaskModal = () => {
    setShowModalBox(false);
  };

  const handleRedirect = () => {
    navigate("/", { state: { worklist_id: worklistId, worklist_number: worklist } });
  };
  // const decryptRole = localStorageStore.getRole();

  const fetchProjectTaskList = async (worklistId, sprint_id = "none", backlog = true) => {
    const res = await getProjectTaskListBySprint(worklistId, sprint_id, backlog);
    if (res?.success) {
      return res;
    } else {
      window.toastr.error(res);
    }
  };

  useEffect(() => {
    let loaded = true;
    fetchProjectTaskList(worklistId, sprint_id, sprint_id === "none" ? true : false).then((response) => {
      if (loaded) {
        setTaskList(response.success && response?.data);
        setFilteredTaskList(response.success && response?.data);
      }
    });
    return () => (loaded = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sprint_id, success]);

  // const handleViewBacklog = async(e) => {
  //   e.preventDefault();
  //   setSelectedSprint('none');
  // }

  const switchMilestoneBadgeClasses = (milestone) => {
    switch (milestone) {
      case 'Todo':
        return 'badge-light-info'
      case 'In Progress':
        return 'bs-yellow-light text-yellow'
      case 'Ready for QA':
        return 'badge-light-danger'
      case 'QA in Progress':
        return 'badge-light-dark'
      case 'Ready for Client Review':
        return 'badge-light-warning'
      case 'Approved':
        return 'badge-light-success'
      default:
        return "badge-light";
    }
  };

  const showTable = () => {
    return filteredTaskList?.tasks?.map((row) => (
      <tr key={row?.id} style={{ color: "var(--bs-primary)", cursor: "pointer" }} onClick={() => navigate(`/task/${row?.task_no}`)} >
        <td className="ps-2">
        <Link to={`/task/${row?.task_no}`}>
          <strong className="text-gray-900">{row?.task_no ?? ""}</strong>
        </Link>
        </td>
        <td>
        <Link to={`/task/${row?.task_no}`}>
          <strong className="text-gray-900">{row?.task_name ?? ""}</strong>
        </Link>
        </td>
        <td>
          <div className={`badge fw-bold ${switchMilestoneBadgeClasses(row?.milestone)}`}>{row?.milestone}</div>
        </td>
      </tr>
    ));
  };
  const decryptRole = localStorageStore.getRole();

  const filterByTaskNameOrNumber = (e) => {
    const value = e.target.value;
    const filteredTasks = taskList?.tasks?.filter((task) => {
      return task?.task_name?.toLowerCase().includes(value.toLowerCase()) || task?.task_no?.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredTaskList({ ...taskList, tasks: filteredTasks });
  }

  return (
    <>
      <div className="col-xl-5">
        <div className="card card-flush mb-6 mb-xl-9">
          <div className="card-header py-4">
            <p className="card-title align-items-start flex-column col-6">
              <span className="fw-bold h4" style={{ maxWidth: "400px" }}>
                Tasks - {`${taskList.sprint_name ?? ""}`}
              </span>
              <span className="text-muted mt-1 fw-semibold fs-6">List of tasks for this sprint</span>
            </p>
            <div className="d-flex py-4">
            <button type="button" className="btn btn-sm btn-light-primary me-2 view-task-btn" onClick={handleRedirect}>
                <span className="svg-icon svg-icon-2">
                  <i className="far fa-eye" />
                </span>
                View Tasks
              </button>
              <button type="button" className="btn btn-primary" onClick={(e) => { setShowModalBox(true); }} >
                <span className="svg-icon svg-icon-2">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                  </svg>
                </span>
                Add Task
              </button>
           
            </div>
          </div>
          <div className="card-body py-0 mb-8">
            <div>
               <div className="input-group input-group-sm mb-3">
                <span className="input-group-text">
                  <i className="fas fa-search"></i>
                </span>
                <input type="text" className="form-control" placeholder="Search task..." onChange={filterByTaskNameOrNumber} />
              </div>
            </div>
            <div className="table-responsive" style={{ maxHeight: "330px", overflow: "auto" }}>
              <table className="table align-middle table-row-dashed fs-6 gy-5">
                <thead>
                  <tr className="text-start text-dark fw-bold fs-7 text-uppercase gs-0">
                    <th className="min-w-80px ps-2">Task #</th>
                    <th className="min-w-80px">Task Name</th>
                    <th className="min-w-80px">Milestone</th>
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-semibold">
                  {taskList?.tasks?.length !== 0 ? (
                    showTable()
                  ) : (
                    <tr>
                      <td>No tasks found...</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {(decryptRole === role.Admin || decryptRole === role.Manager) && <ProjectPlan worklistId={worklistId} masterData={masterData}/>}
        
      </div>
     
      {showModalBox && <WorklistAddTaskModal showModalBox={showModalBox} CloseTaskModal={CloseTaskModal} masterData={masterData} worklistId={worklistId} dependencyForFetchWorklistProgressRerender={dependencyForFetchWorklistProgressRerender} setDependencyForFetchWorklistProgressRerender={setDependencyForFetchWorklistProgressRerender} projectmemberList={projectmemberList} success={success} setSuccess={setSuccess} configurationProvider={configurationProvider} />}
    </>
  );
}
export default TaskList;
