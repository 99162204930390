import { Modal } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import { addLogHours, updateLogHours } from "../../services/chartService";
import { useEffect, useState } from "react";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { getUserDetail } from "../../services/userService";

function LogHoursModal({ showModalBox, setShowModalBox, setSuccess, success, handleModalClose, modalValues, masterdata}) {
  const [hours, setHours] = useState(modalValues.hours);
  const [logDate, setLogDate] = useState(modalValues.date);
  const [hourTags, setHourTags] = useState(modalValues.tag_id ?? {label:"", value: "", id:""});
  const [logDescription, setLogDescription] = useState(modalValues.description);
  const [isValid, setIsValid] = useState(true);

  const options = masterdata?.tags?.filter((tag)=> {
    return tag.value===1 && tag;
  })

  const handleLogHoursSave = (e) => {
    e.preventDefault();
    const isFormValid = validateInput([hours, logDate, logDescription])
    if (modalValues.modal_type === "add") {
      const button = document.getElementById("savebutton");
      button.disabled = true;
      if(isFormValid){
        setIsValid(true);
        addLogHours(modalValues.task_id, { hours: hours, date: logDate, description: logDescription, hourTags: hourTags?.id}).then((res) => {
          button.disabled = false;
          if (res?.success === true) {
            window.toastr.success(res?.message);
            setSuccess(!success);
            setShowModalBox(false);
          } else {
            window.toastr.error(res?.message);
            
          }
        });
      } else {
        setIsValid(false);
        button.disabled = false;
      }
    }
    if (modalValues.modal_type === "update") {
      if(isFormValid){
      setIsValid(true);
      updateLogHours(modalValues.task_id, modalValues.id, { hours: hours, date: moment(logDate,"DD-MM-YYYY").format('DD-MM-YYYY'), description: logDescription, hourTags: hourTags?.id }).then((res) => {
        if (res?.success === true) {
            window.toastr.success(res?.message);
            setSuccess(!success);
            setShowModalBox(false);
          } else {
            window.toastr.error(res?.message);
          }
        });
      } else {
        setIsValid(false);
      }
    }
  };

  const handleTags = (event) => {
    setHourTags({
      label: event?.label,
      value: event?.value,
      id: event?.id
    })
  };


  const validateInput = (inputs) => {
    let isValid = true;  
    inputs.forEach(input => {
      if(input === "" || input === null || input === undefined){
        isValid = false;       
      }
    })
    return isValid;
  }

  const tagByDesignation = async () => {
    const userData = await getUserInfo();
    if(hourTags !== null && hourTags !== undefined && hourTags !== "" && hourTags.id !== ''){
      options?.forEach((tag)=> {
       if(tag.id === hourTags){
          setHourTags(tag);
        }
      })      
    }
    else if(userData?.id === modalValues?.assignee_id){
      const designation = localStorage.getItem("designation");
        if(designation.toLowerCase().includes("developer") || designation.toLowerCase().includes("engineer") || designation.toLowerCase().includes("technical")){
          setHourTags(()=> ({label: "Coding & Unit Testing", value:"1", id: "2" }))
        }
        else if(designation.toLowerCase().includes("quality") || designation.toLowerCase().includes("qa")){
          setHourTags(()=> ({label: "Testing", value:"1", id: "3" }))
        }
        else if(designation.toLowerCase().includes("manager") || designation.toLowerCase().includes("admin")){
         setHourTags(()=> ({label: "Project Management", value:"1", id: "4" }))
        }
        else{
         setHourTags(() => ({label: "Coding & Unit Testing", value:"1", id: "2" }))
        }
    }
    else{
        setHourTags(() => ({label: "", value:""}))
      }
    }

  const getUserInfo = async () => {
    const token = localStorage.getItem('token');
    var response = await getUserDetail(token);
    if(response.success){
      const jsonResponse = response.data;
      if(jsonResponse) {
        localStorage.setItem('designation', jsonResponse.Designation.name);
      }
    }
    return response.data;
  }

  useEffect(()=> {

    tagByDesignation();
    // eslint-disable-next-line
  },[])


  return (
    <Modal show={showModalBox} centered>
      <Modal.Body className="modal-body scroll-y mb-5">
        <div id="location">
          <div className="applicant-fileds">
            <div className="form-group">
              <div data-repeater-list="kt_ecommerce_add_product_options" className="d-flex flex-column gap-3">
                <div data-repeater-item="" className="form-group d-flex flex-wrap align-items-center gap-2">
                  <label className="form-label mb-2">Log hours for this task</label>
                  <div className="card-body p-2">
                    <div className="card-body d-flex flex-column pb-0">
                      <div className="d-flex flex-column gap-5 gap-md-7">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-6">
                            <label className="form-label">Task #</label>
                            <input className="form-control" name="task_no" defaultValue={modalValues?.task_no} disabled autoComplete="off" />
                          </div>
                          <div className="col-xl-6 col-lg-6 col-6">
                            <label className="form-label">Assignee</label>
                            <input className="form-control" autoComplete="off" defaultValue={modalValues?.assignee_name} name="assignee_name" disabled />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-6">
                            <OverlayTrigger placement={"top"} overlay={<Tooltip> Formats supported: 02:30, 2h30m, 2h, 30m, 2.5 </Tooltip>}>
                              <label className="required form-label">Log Hours <i className="fas fa-exclamation-circle ms-1 fs-6"></i></label>
                            </OverlayTrigger>
                            <input
                              type="text"
                              className="form-control"
                              autoComplete="off"
                              defaultValue={hours ?? "01:00"}
                              onChange={(e) => {
                                e.preventDefault();
                                setHours(e.target.value);
                              }}
                              name="log_hours"
                              placeholder="HH:MM"
                              disabled={modalValues.modal_type === "view" ? true : false}
                            />
                          </div>
                          <div className="col-xl-6 col-lg-6 col-6">
                            <label className="required form-label">Log Date</label>
                            <Flatpickr
                              className="form-control"
                              options={{ dateFormat: "d-m-Y", animate: true, maxDate: moment().format('DD-MM-YYYY') }}
                              value={logDate}
                              name="start_date"
                              onChange={(e) => {
                                setLogDate(moment(e[0]).format("DD-MM-YYYY"));
                              }}
                              disabled={modalValues.modal_type === "view" ? true : false}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-12 col-lg-12 col-12">
                            <label className="required form-label">Log Description</label>
                            <textarea
                              required
                              autoComplete="off"
                              className="form-control"
                              name="log_description"
                              defaultValue={logDescription}
                              onChange={(e) => {
                                e.preventDefault();
                                if(e.target.value !== ''){
                                  setIsValid(true);
                                }
                                setLogDescription(e.target.value);
                              }}
                              disabled={modalValues.modal_type === "view" ? true : false}
                              placeholder="Type your description here..."
                            />
                            {!isValid && <p className="help is-danger errorDanger">Description must be filled</p>}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-12 col-lg-12 col-12">
                            <label className="form-label">Tags</label>
                            <Select className="col-xl-12 col-12"
                            options={options}
                            onChange={handleTags}
                            value={hourTags}
                            isDisabled={modalValues.modal_type === "view" ? true : false}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center pt-8 mt-10">
          <button type="reset" className="btn btn-light me-3 btn-active-light-primary" onClick={modalValues.modal_type === "view" ? () => setShowModalBox(false) : handleModalClose}>
            {modalValues.modal_type === "view" ? "Close" : "Cancel"}
          </button>
          {modalValues.modal_type !== 'view' ? (<button className="btn btn-primary me-4" id="savebutton" type="button" onClick={modalValues.modal_type === "view" ? null : handleLogHoursSave} disabled={modalValues.modal_type === "view" ? true : false}>
            <span>Save</span>
          </button>) : null}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LogHoursModal;
