const managerConstants = {}

managerConstants.GET_REPORTS = 'reports'

managerConstants.GET_FILTERS = 'reports/fields'

managerConstants.GET_ALL_TEMPLATES = 'reports/templates'

managerConstants.GET_TEMPLATE_CONFIG = 'reports/template-configuration/ID'

managerConstants.CREATE_REPORTS_TEMPLATE = 'reports/template'

managerConstants.UPDATE_REPORTS_TEMPLATE = 'reports/template-configuration/ID'

managerConstants.GET_FILTER_OPTIONS = 'v2/hn-master-data'





export default managerConstants