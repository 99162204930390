import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { checkUserAuthenticatedAndAttending } from '../../services/monitorService'

function NewFeatures({ role, authenticated = false }) {
    const [show, setShow] = useState(false);
    
    useEffect(() => {
        authenticated && (role === "Team Lead" || role === "Manager")
            ? handleAutoDisplayForFirstTime()
            : checkUserAuthenticationAndAttendance();

        // eslint-disable-next-line
    }, [role]);

    const checkUserAuthenticationAndAttendance = async () => {
        try {
            const response = await checkUserAuthenticatedAndAttending();
            if (response?.success) {
                handleAutoDisplayForFirstTime();
            }

        } catch (err) {
            err?.message && window.toastr.error(err?.message);
        }
    }

    const handleAutoDisplayForFirstTime = () => {
        const autoDisplayForFirstTime = localStorage.getItem("show-features");
        if (!autoDisplayForFirstTime) {
            setShow(true);
            localStorage.setItem("show-features", true);
        }
    };
    return (
        <div className="cursor-pointer focus-ring me-1" data-kt-menu-trigger="{default:'click', sm: 'hover'}" style={{ width: '40px', height: '40px', display: 'grid', placeItems: 'center', borderRadius: '50%' }}>
            <Link onClick={e => {
                e.preventDefault();
                setShow(true);
            }} href="#" data-kt-menu-trigger="{default:'click', sm: 'hover'}" className="btn btn-icon h-35px w-md-40px h-md-40px" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                <span className="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M4.5 16.5c-1.5 1.26-2 5-2 5s3.74-.5 5-2c.71-.84.7-2.13-.09-2.91a2.18 2.18 0 0 0-2.91-.09z" /><path d="m12 15-3-3a22 22 0 0 1 2-3.95A12.88 12.88 0 0 1 22 2c0 2.72-.78 7.5-6 11a22.35 22.35 0 0 1-4 2z" /><path d="M9 12H4s.55-3.03 2-4c1.62-1.08 5 0 5 0" /><path d="M12 15v5s3.03-.55 4-2c1.08-1.62 0-5 0-5" /></svg>
                    <span className="badge badge-circle badge-info notification position-absolute translate-middle">!</span>
                </span>
            </Link>

            <Modal show={show} onHide={() => setShow(false)} size="xl" centered>
                <Modal.Body className="modal-body" >
                    <div>
                        <div className="d-flex flex-column me-2" >
                            <div className="col-12">
                                <div className="feature-description">
                                    <div className="d-flex align-items-center justify-content-between py-4">
                                        <h1 style={{ fontSize: '2rem' }} className="text-primary">User Tagging Feature</h1>
                                        <span className="badge badge-info notification fs-5">Aug 21, 2024</span>
                                    </div>
                                    <p className="fs-3">
                                        The new <strong>User Tagging</strong> feature allows users to easily mention and notify others in comments
                                        by typing <code>@</code> followed by the user's name. When tagged, the mentioned user will
                                        receive a notification, making it easier to draw attention to specific comments or request input.
                                    </p>
                                </div>

                                <div className="how-to-use">
                                    <h1 className="py-4">How to Use:</h1>
                                    <ul className="fs-3">
                                        <li><strong>Step 1:</strong> In the comment box, type <code>@</code> followed by the name of the user you want to tag.</li>
                                        <li><strong>Step 2:</strong> A dropdown list will appear, showing matching user names as you type.</li>
                                        <li><strong>Step 3:</strong> Select the desired user from the list.</li>
                                        <li><strong>Step 4:</strong> Complete your comment and post it. The tagged user will be notified automatically.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="d-flex flex-center flex-row p-4 bg-light-warning milestone_border mt-4" style={{ borderRadius: "10px" }}>
                                <div className="col-6">
                                    <div style={{
                                        height: "250px", padding: "10px", borderRadius: "10px"
                                    }}>
                                        <img style={{
                                            height: '100%', width: '100%', objectFit: 'cover', borderRadius: '10px',
                                            boxShadow: '5px 5px 5px rgba(0,0,0,.4)'
                                        }} src="assets/media/newFeatures/commenttag.png" alt="feature" />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div style={{ height: "250px", padding: "10px", borderRadius: "10px" }}>
                                        <img style={{
                                            height: '100%', width: '100%', objectFit: 'cover', borderRadius: '10px',
                                            boxShadow: '5px 5px 5px rgba(0,0,0,.4)'
                                        }} src="assets/media/newFeatures/commenttag1.png" alt="feature" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <button type="reset" onClick={() => setShow(false)} id="manager_submit" className="btn fw-bold btn-primary mt-5 mx-auto relative d-block">Close</button>
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default NewFeatures;