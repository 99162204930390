
import { useEffect, useState } from "react";
import { getMasterData } from "../../../services/chartService";
import TimesheetTable from "./TimesheetTable";

const Timesheet = () => {

  const [masterData, setMasterData] = useState();
  useEffect(() => {
    fetchChartData();
  }, []);

  const fetchChartData = async () => {
    getMasterData()
      .then((response) => {
        setMasterData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <TimesheetTable masterData={masterData} />
    </>
  );
};
export default Timesheet;
