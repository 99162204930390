import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
function WorklistProgress({ worklistDetail}) {

  const [series,setSeries] = useState([])

  useEffect(() => {
    if (worklistDetail !== null) {
      setSeries([worklistDetail?.milestone_counts?.todoTasksCount, worklistDetail?.milestone_counts?.inProgressTasksCount, worklistDetail?.milestone_counts?.readyForQATasksCount,
        worklistDetail?.milestone_counts?.qaInProgressTasksCount, worklistDetail?.milestone_counts?.readyForClientReviewTasksCount, worklistDetail?.milestone_counts?.approvedTasksCount]);
    }
  }, [worklistDetail]);

  const labels = ["ToDo", "In Progress", "Ready for QA", "QA in progress", "Ready for Client Review", "Approved"];
  
  return (
    <>
      <div className="col-xl-3">
        <div className="card card-flush mb-6 mb-xl-9" style={{ maxHeight: "300px", minHeight: "300px" }}>
          <div className="card-header pt-5">
            <div className="card-title d-flex flex-column">
              <div className="d-flex align-items-center">
                <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">{worklistDetail?.progressPercentage} %</span>
              </div>
              <span className="text-gray-400 pt-1 fw-semibold fs-6">Project progress</span>
            </div>
            <div className="card-toolbar d-block">
              <OverlayTrigger key={"right"} placement={"top"} overlay={<Tooltip id="tooltip-right">Tasks completed / Total tasks * 100</Tooltip>}>
                <i className="fas fa-exclamation-circle ms-2 fs-2" />
              </OverlayTrigger>
            </div>
          </div>
          <div className="card-body pt-6 pb-6 d-flex align-items-center chart-progress">
            <div className="d-flex flex-center me-5 pt-2">
              <div id="donutchart" style={{ width: 80, height: 80 }}>
                <div style={{ position: "relative" }}>
                  <div dir="ltr" style={{ position: "relative", width: 120, height: 120 }}>
                    {(worklistDetail?.milestone_counts?.todoTasksCount !== 0 || worklistDetail?.milestone_counts?.inProgressTasksCount !== 0 || worklistDetail?.milestone_counts?.readyForQATasksCount !== 0 || worklistDetail?.milestone_counts?.qaInProgressTasksCount !== 0 || worklistDetail?.milestone_counts?.readyForClientReviewTasksCount !== 0 || worklistDetail?.milestone_counts?.approvedTasksCount !== 0) && (
                      <div aria-label="A chart." style={{ position: "absolute", left: -30, top: -10, width: "100%", height: "100%" }}>
                        <Chart
                          options={{
                            labels,
                            legend: {
                              show: false,
                            },
                            dataLabels: {
                              enabled: false,
                            },
                            plotOptions: {
                              pie: {
                                donut: {
                                  labels: {
                                    show: true,
                                    name: {
                                      show: false,
                                    },
                                    value: {
                                      show: true,
                                      color: "var(--bs-text-dark)",
                                      fontFamily: "var(--bs-font-sans-serif)",
                                      fontSize: "2rem",
                                      formatter: function (worklistDetail) {
                                        return worklistDetail?.total_tasks;
                                      },
                                    },
                                    total: {
                                      show: true,
                                      label: ""
                                    },
                                  },
                                },
                              },
                            },
                            colors: ["#7239ea", "#ffae83", "#f1416c", "#1b1b29", "#ffc60b", "#50cd89"],
                          }}
                          series={series}
                          type="donut"
                          width="150"
                        />
                        <div aria-label="A tabular representation of the data in the chart." style={{ position: "absolute", left: "-10000px", top: "auto", width: 1, height: 1, overflow: "hidden" }}></div>
                      </div>
                    )}
                  </div>
                  <div aria-hidden="true" style={{ display: "none", position: "absolute", top: 130, left: 130, whiteSpace: "nowrap", fontFamily: "Arial", fontSize: 8, fontWeight: "bold" }}></div>
                  <div />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column content-justify-center w-100 ms-3">
              <div className="d-flex fs-6 fw-semibold align-items-center mb-1">
                <div className="bullet w-8px h-6px rounded-2 bg-info me-3" />
                <div className="text-gray-500 flex-grow-1 me-4">ToDo</div>
                <div className="fw-bolder text-gray-700 text-xxl-end">{worklistDetail?.milestone_counts?.todoTasksCount}</div>
              </div>
              <div className="d-flex fs-6 fw-semibold align-items-center mb-1">
                <div className="bullet w-8px h-6px rounded-2 bg-warning me-3" />
                <div className="text-gray-500 flex-grow-1 me-4">In progress</div>
                <div className="fw-bolder text-gray-700 text-xxl-end">{worklistDetail?.milestone_counts?.inProgressTasksCount}</div>
              </div>
              <div className="d-flex fs-6 fw-semibold align-items-center mb-1">
                <div className="bullet w-8px h-6px rounded-2 bg-warning me-3" />
                <div className="text-gray-500 flex-grow-1 me-4">Dev Done</div>
                <div className="fw-bolder text-gray-700 text-xxl-end">{worklistDetail?.milestone_counts?.devDoneTasksCount}</div>
              </div>
              <div className="d-flex fs-6 fw-semibold align-items-center mb-1">
                <div className="bullet w-8px h-6px rounded-2 bg-danger me-3" />
                <div className="text-gray-500 flex-grow-1 me-4">Ready for QA</div>
                <div className="fw-bolder text-gray-700 text-xxl-end">{worklistDetail?.milestone_counts?.readyForQATasksCount}</div>
              </div>
              <div className="d-flex fs-6 fw-semibold align-items-center mb-1">
                <div className="bullet w-8px h-6px rounded-2 bg-dark me-3" />
                <div className="text-gray-500 flex-grow-1 me-4">QA in progress</div>
                <div className="fw-bolder text-gray-700 text-xxl-end">{worklistDetail?.milestone_counts?.qaInProgressTasksCount}</div>
              </div>
              <div className="d-flex fs-6 fw-semibold align-items-center mb-1">
                <div className="bullet w-8px h-6px rounded-2 bg-warning me-3" />
                <div className="text-gray-500 flex-grow-1 me-4">QA Done</div>
                <div className="fw-bolder text-gray-700 text-xxl-end">{worklistDetail?.milestone_counts?.qaDoneTasksCount}</div>
              </div>
              <div className="d-flex fs-6 fw-semibold align-items-center mb-1">
                <div className="bullet w-8px h-6px rounded-2 bg-yellow me-3" />
                <div className="text-gray-500 flex-grow-1 me-4">Ready for Client Review</div>
                <div className="fw-bolder text-gray-700 text-xxl-end">{worklistDetail?.milestone_counts?.readyForClientReviewTasksCount}</div>
              </div>
              <div className="d-flex fs-6 fw-semibold align-items-center">
                <div className="bullet w-8px h-6px rounded-2 bg-success me-3" />
                <div className="text-gray-500 flex-grow-1 me-4">Approved</div>
                <div className="fw-bolder text-gray-700 text-xxl-end">{worklistDetail?.milestone_counts?.approvedTasksCount}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default WorklistProgress;
