import React, { useState} from "react";
import ToDoList from "./ToDoList";
import {useLocation} from "react-router-dom";

const Index = () => {
  const [query, setQuery] = useState();
  const [filterForToDOList, setFilterForToDoList] = useState({})

  const attendanceProp = false;
  const location = useLocation();
 
  return (
    <>
      <ToDoList showtable={location?.state?.showtable} onQuery={setQuery} setFilterForToDoList={setFilterForToDoList} filterForToDOList={filterForToDOList} attendanceProp={attendanceProp} show={location?.state?.show} query={query}/>
    </>
  );
};

export default Index;
