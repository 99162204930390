import { useEffect, useState } from "react";
import WorklistProgress from "./WorklistProgress";
import WorklistNewChart from "./WorklistNewChart";
import { fetchWorklistRecord } from "../../../services/managerWorkListService";
import EpicManagement from "./EpicManagement";
const WorklistDetailsHeader = ({ worklistId, setWorklist, dependencyForFetchWorklistProgressRerender, setDependencyForFetchWorklistProgressRerender, masterData, worklist, success, setSuccess, setProjectMembersList }) => {
  const [worklistStat, setWorklistStat] = useState(null);
  const [renderProjectData, setRenderProjectData] = useState(true);
  const fetchWorklistData = async () => {
    const res = await fetchWorklistRecord(worklistId);
    setWorklistStat(res);
    setWorklist(res?.worklist_no);
  };

  useEffect(() => {
    fetchWorklistData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderProjectData, dependencyForFetchWorklistProgressRerender]);

  const handleProjectDetailsData = (response) => {
    setRenderProjectData(response);
  };
  return (
    <>
      <div className="col-xl-12 col-lg-12 col-md-12">
        <div className="row gy-5 g-xl-10">
          <div className="col-xl-4">
            <div className="card card-flush mb-6 mb-xl-9 card1 chart_card">
              <WorklistNewChart worklistDetail={worklistStat} worklistId={worklistId} dependencyForFetchWorklistProgressRerender={dependencyForFetchWorklistProgressRerender} setDependencyForFetchWorklistProgressRerender={setDependencyForFetchWorklistProgressRerender} setProjectMembersList={setProjectMembersList} masterData={masterData} handleProjectDetailsData={handleProjectDetailsData} />
            </div>
          </div>
          <EpicManagement worklistId={worklistId} success={success} setSuccess={setSuccess} />
          <WorklistProgress worklistDetail={worklistStat} />
        </div>
      </div>
    </>
  );
};
export default WorklistDetailsHeader;
