import { Modal } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import { useEffect, useState } from "react";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { fetchTaskForTimesheet } from "../../../services/timeSheetService";
import { getUserDetail } from "../../../services/userService";
import { addLogHours } from "../../../services/chartService";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";

const TimesheetLogHoursModal = ({ lastSelectedProject, setLastSelectedProject, showModalBox, setShowModalBox, handleRerenderdata, projectList, masterData }) => {
  const [taskId, setTaskId] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState({});
  const [disable, setDisable] = useState(true);
  const [hourTags, setHourTags] = useState({ label: "", value: "", id: "" });
  const todayDate = moment().format("DD-MM-YYYY");

  const options = masterData?.tags?.filter((tag) => {
    return tag.value === 1 && tag;
  });

  useEffect(() => {
    fetchLoggedInUserDetails();
    setHoursDate();
    tagByDesignation();
    // eslint-disable-next-line
  }, []);

  const handleTags = (event) => {
    setHourTags({
      label: event?.label,
      value: event?.value,
      id: event?.id,
    });
  };

  const fetchLoggedInUserDetails = () => {
    getUserDetail()
      .then((response) => {
        if (response.success === true) {
          setLoggedInUser({ id: response?.data?.id, name: `${response?.data?.first_name} ${response?.data?.last_name}` });
        } else {
          window.toastr.error(response.message);
        }
      })
      .catch((error) => {
        window.toastr.error(error);
      });
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues
  } = useForm();

  function setHoursDate() {
    setValue("hours", "01:00");
    setValue("date", todayDate);
  }
  const hoursRegex = /^[0-9]{1,2}:[0-5]{0,1}[0-9]{1}$|^[0-9]{1,2}h[0-5]{0,1}[0-9]{1}m$|^[0-9]{1,2}h$|^[0-5]{0,1}[0-9]{1}m$|^[0-9]{0,2}\.[0-9]{1,2}$/;

  const handleLogHoursSave = (data) => {
    const button = document.getElementById("savebutton");
    button.disabled = true;
    let taskId = data?.taskvalue?.value;
    let obj = {
      hours: data?.hours,
      date: moment(data?.date, "DD-MM-YYYY").format("DD-MM-YYYY"),
      description: data?.description,
      hourTags: hourTags?.id,
    };
    addLogHours(taskId, obj).then((res) => {
      button.disabled = false;
      if (res?.success === true) {
        window.toastr.success(res?.message);
        
        if(lastSelectedProject) {
          setValue('project', lastSelectedProject);
          setValue('taskvalue', null);
          setValue('hours', '');
          setValue('description', '');
        } else {
          setShowModalBox(false);
        }
        handleRerenderdata(res);
      } else {
        window.toastr.error(res?.message);
      }
    });
  };

  const tagByDesignation = async () => {
    const designation = localStorage.getItem("designation");
    if(designation) {
      if (designation.toLowerCase().includes("developer") || designation.toLowerCase().includes("engineer") || designation.toLowerCase().includes("technical")) {
        setHourTags(() => ({ label: "Coding & Unit Testing", value: "1", id: "2" }));
      } else if (designation.toLowerCase().includes("quality") || designation.toLowerCase().includes("qa")) {
        setHourTags(() => ({ label: "Testing", value: "1", id: "3" }));
      } else if (designation.toLowerCase().includes("manager") || designation.toLowerCase().includes("admin")) {
        setHourTags(() => ({ label: "Project Management", value: "1", id: "4" }));
      } else {
        setHourTags(() => ({ label: "Coding & Unit Testing", value: "1", id: "2" }));
      }
    }
  };

  const renderTaskList = () => {
    return taskId?.map((task) => ({
      label: task?.name,
      value: task?.id,
    }));
  };

  const renderProjectList = () => {
    return projectList?.map((project) => ({
      label: `${project?.name} ( ${project?.worklist_no} )`,
      value: project?.id,
    }));
  };

  const handleProject = async (event) => {
    setLastSelectedProject(event);
    setDisable(false);
    const response = await fetchTaskForTimesheet(event?.value);
    setTaskId(response);
    setValue("taskvalue", null);
  };

  const handleModalClose = () => {
    if(getValues('description').length > 5) {
      Swal.fire({
        text: "Are you sure you would like to cancel?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes, cancel it!",
        cancelButtonText: "No, return",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-active-light",
        },
      })
      .then((result) => {
        if (result.value) {
          setShowModalBox(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    } else {
      setLastSelectedProject(null);
      setShowModalBox(false);
    }
  };
    
  return (
    <>
      <Modal show={showModalBox} centered>
        <Modal.Header>
          <h2 className="fw-bold">Log hours for this task</h2>
        </Modal.Header>
        <div className="modal-body py-10 px-lg-8">
          <form onSubmit={handleSubmit(handleLogHoursSave)}>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-6 fv-row mb-7">
                <label className="required form-label fs-6 fw-semibold mb-2">Project #</label>
                <Controller
                  name="project"
                  control={control}
                  rules={{ required: "Required" }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={renderProjectList()}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                        handleProject(selectedOption);
                      }}
                      placeholder="Select Project ...."
                      defaultValue={lastSelectedProject}
                    />
                  )}
                />
                <small className="text-danger">{errors?.project && errors.project.message}</small>
              </div>
              <div className="col-xl-6 col-lg-6 col-6 fv-row mb-7">
                <label className="required form-label fs-6 fw-semibold mb-2">Task #</label>
                <Controller name="taskvalue" control={control} rules={{ required: "Required" }} render={({ field }) => <Select {...field} options={renderTaskList()} placeholder="Select Task ...." isDisabled={disable} />} />
                <small className="text-danger">{errors?.taskvalue && errors.taskvalue.message}</small>
              </div>
            </div>
            <div className="row">
              <div className="col-12 fv-row mb-7">
                <label className="form-label fs-6 fw-semibold mb-2">Assignee</label>
                <input className="form-control" autoComplete="off" defaultValue={loggedInUser.name} name="assignee_name" disabled />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-6 fv-row mb-7">
                <OverlayTrigger placement={"top"} overlay={<Tooltip> Formats supported: 02:30, 2h30m, 2h, 30m, 2.5 </Tooltip>}>
                  <label className="required form-label fs-6 fw-semibold mb-2">
                    Log Hours <i className="fas fa-exclamation-circle ms-1 fs-6"></i>
                  </label>
                </OverlayTrigger>
                <input
                  type="text"
                  className="form-control"
                  name="hours"
                  placeholder="HH:MM"
                  {...register("hours", {
                    required: "Required",
                    pattern: {
                      value: hoursRegex,
                      message: "Invalid format. Please follow the specified formats.",
                    },
                  })}
                />
                <small className="text-danger">{errors?.hours && errors.hours.message}</small>
              </div>
              <div className="col-xl-6 col-lg-6 col-6 fv-row mb-7">
                <label className="required form-label fs-6 fw-semibold mb-2">Log Date</label>
                <Controller name="date" control={control} render={({ field }) => <Flatpickr id="date" className="form-control" placeholder="" options={{ dateFormat: "d-m-Y", animate: true, maxDate: moment().format("DD-MM-YYYY") }} value={field.value} onChange={(date) => field.onChange(date[0])} />} />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-12 fv-row mb-7">
                <label className="required form-label fs-6 fw-semibold mb-2">Log Description</label>
                <textarea autoComplete="off" className="form-control" name="description" placeholder="Type your description here..." {...register("description", { required: "Required" })} rows={5}/>
                <small className="text-danger">{errors?.description && errors.description.message}</small>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-12">
                <label className="form-label">Tags</label>
                <Select className="col-xl-12 col-12" options={options} onChange={handleTags} value={hourTags} />
              </div>
            </div>
            <div className="text-center pt-5">
              <button type="button" className="btn btn-light me-3 btn-active-light-primary" onClick={handleModalClose}>
                Cancel
              </button>
              <button type="submit" id="savebutton" className="btn btn-primary me-4" onClick={() => (setLastSelectedProject(0))}>
                Save
              </button>
              <button type="submit" id="saveButtonWithInsertNext" className="btn btn-primary me-4">
                Save & Insert Next
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default TimesheetLogHoursModal;
