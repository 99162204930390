import React, { useContext, useEffect, useState } from "react";
import TaskList from "./TaskList";
import { getMasterData } from "../../../services/chartService";
import { useLocation } from "react-router-dom";
import WorklistDetailsHeader from "./WorklistDetailsHeader";
import { useNavigate } from "react-router-dom";
import Configurationcontext from "../../../Context/ConfigrationContext/Configurationcontext";
import SprintManagement from "./SprintManagement";
import './EpicSprintManagement/ManagementDetailsModal.css';

function WorklistDetails() {
  const configurationProvider = useContext(Configurationcontext)
  const [dependencyForFetchWorklistProgressRerender, setDependencyForFetchWorklistProgressRerender] = useState(true);
  const [key, setKey] = useState(0);
  const [masterData, setMasterData] = useState();
  const location = useLocation();
  const [worklist, setWorklist] = useState();
  const worklist_id = location?.state?.worklist_id;
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [selectedSprint, setSelectedSprint] = useState('none');
  const [projectmemberList,setProjectMembersList] = useState();

  if(location?.state === null) {
    navigate('/manager-worklist');
  }

  useEffect(() => {
    fetchMasterData();
  }, []);

  const fetchMasterData = async () => {
    getMasterData()
      .then((response) => {
        setMasterData(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [worklist_id]);
  
  return (
    <>
        <div className="row">
          <WorklistDetailsHeader key={key} worklistId={worklist_id} setWorklist={setWorklist} dependencyForFetchWorklistProgressRerender={dependencyForFetchWorklistProgressRerender} setDependencyForFetchWorklistProgressRerender={setDependencyForFetchWorklistProgressRerender} masterData={masterData} worklist={worklist} success={success} setSuccess={setSuccess} setProjectMembersList={setProjectMembersList}/>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="row gy-5 g-xl-10">
              <SprintManagement worklistId={worklist_id} success={success} setSuccess={setSuccess} setSelectedSprint={setSelectedSprint}/>
              <TaskList worklistId={worklist_id} success={success} selectedSprint={selectedSprint} setSelectedSprint={setSelectedSprint} dependencyForFetchWorklistProgressRerender={dependencyForFetchWorklistProgressRerender} setDependencyForFetchWorklistProgressRerender={setDependencyForFetchWorklistProgressRerender} masterData={masterData} worklist={worklist} setSuccess={setSuccess} projectmemberList={projectmemberList} configurationProvider = {configurationProvider} />            
             </div>
          </div>
        </div>
    </>
  );
}
export default WorklistDetails;
