import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Container, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Flatpickr from "react-flatpickr";
import { allocateVol } from "../../../services/managerService";
import { useNavigate } from "react-router-dom";
import "../ManagerWorklistDetails.css";
import moment from "moment/moment";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { userRoleConstants } from "../../../utils/constants";

const WatchListBodyAddVol = ({ masterData, changeParentList, onQuery }) => {
  const [showModalBox, setShowModalBox] = useState(false);
  const navigate = useNavigate();
  const options_for_end_date = {
    dateFormat: "d/m/Y",
  };

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  function addWeeks(startDate, numberOfWeeks) {
    const newDate = moment(startDate, "DD/MM/YYYY").add(numberOfWeeks, "weeks").format("DD/MM/YYYY");
    setValue("end_date", newDate);
  }

  function setDurationBasedOnEndDate(startDate, endDate) {
    const duration = moment.duration(moment(endDate, "DD/MM/YYYY").diff(moment(startDate, "DD/MM/YYYY"))).asWeeks().toFixed(0);
    setValue("duration", duration);
  }

  const registerOptions = {
    worklist_name: { required: "Required", pattern: { value: /^\S/, message: "Invalid input" }, minLength: { value: 3, message: "Minimum 3 characters required." }, maxLength: { value: 100, message: "Exceeded max limit" } },
    ClientId: { required: "Required" },
    // no_of_charts: { required: "Required", pattern: { value: /^-?\d+$/, message: "Invalid input" }, max: { value: 99999, message: "Exceeded max limit" }, min: { value: 1, message: "Invalid input" } },
    ProcessId: { required: "Required" },
    // LocationId: { required: "Required" },
    start_date: { required: "Required" },
    duration: { required: "Required", min: { value: 0, message: "Minimum value is 0" }, max: { value: 100, message: "Maximum value is 100" } },
    SpecialtyId: { required: "Required" },
    end_date: { required: "Required" },
    project_member: { required: "Required" },
    project_owner: { required: "Required" },
  };
  const handleSubmitPreview = async (type) => {
    await handleSubmit((data) => {
      const button = document.getElementById("savebutton");
      button.disabled = true;
      const selectedSpecialties = data?.SpecialtyId?.map((option) => option?.value);
      const selectedProjectMember = data?.project_member?.map((option) => option?.value);
      const selectedProjectOwner = data?.project_owner?.value;
      data = { ...data, SpecialtyId: selectedSpecialties, project_member: selectedProjectMember, project_owner: selectedProjectOwner, duration: parseInt(data?.duration) };
      allocateVol(data)
        .then((data) => {
          button.disabled = false;
          if (data?.success === true) {
            if (type !== "allocate") {
              window.toastr.success("Project added successfully");
            }
            changeParentList(true);
            setShowModalBox(false);
            onQuery(data);
            reset({
              worklist_name: "",
              SpecialtyId: "",
              ClientId: "",
              ProcessId: "",
              project_member: "",
              project_owner: "",
              duration: "",
            });
            if (type === "allocate") {
              navigate("/manager-worklist-details", { state: { worklist_id: data?.data?.newVolume?.id } });
            }
          } else {
            if (data?.error) {
              let errors = data?.error?.map((key) => {
                return key.message;
              });
              window.toastr.error(errors);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    })();
  };

  useEffect(() => {}, [masterData]);
  const renderAllUsers = (type) => {
    if (type === "members") {
      return masterData?.users_all?.map((data) => {
        return {
          label: `${data?.name}`,
          value: data?.id,
        };
      });
    } else if (type === "owners") {
      const {ADMIN_ID, MANAGER_ID } = userRoleConstants;
      // eslint-disable-next-line array-callback-return
      return masterData?.users_all
        ?.map((data) => {
          if (data?.RoleId === ADMIN_ID || data?.RoleId === MANAGER_ID) {
            return {
              label: `${data?.name}`,
              value: data?.id,
            };
          }
          return null;
        })
        .filter(Boolean);
    }
  };

  const renderSpecialties = () => {
    const groupedOptions = [];
    if (masterData?.speciality_by_techstack_category) {
      masterData?.speciality_by_techstack_category.forEach((data) => {
        // Check if the group already exists in groupedOptions
        const group = groupedOptions.find((group) => group.label === data.group_name);
        if (group) {
          // If the group exists, add the specialty to its options
          group.options.push({
            label: data.spec_name,
            value: data.id,
          });
        } else {
          // If the group doesn't exist, create a new group
          groupedOptions.push({
            label: data.group_name,
            options: [
              {
                label: data.spec_name,
                value: data.id,
              },
            ],
          });
        }
      });
    }
    return groupedOptions;
  };
  const customStyles = {
    groupHeading: (provided) => ({
      ...provided,
      fontWeight: "bold",
      fontSize: "14px",
      color: "#404040",
    }),
  };

  return (
    <>
      <div className="d-flex justify-content-end mb-4" data-kt-user-table-toolbar="base">
        <button
          type="button"
          className="btn btn-primary"
          onClick={(e) => {
            setShowModalBox(true);
          }}
        >
          <span className="svg-icon svg-icon-2">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor" />
              <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor" />
            </svg>
          </span>
          New Project
        </button>
      </div>
      <Container>
        <Modal show={showModalBox} size="lg" centered>
          <div>
            <div>
              <Modal.Header className="modal-header py-4">
                <h2 className="fw-bold">Add New Project</h2>
                <Modal.Title
                  className="btn btn-icon btn-sm btn-active-icon-primary"
                  onClick={(e) => {
                    setShowModalBox(false);
                    reset({
                      keepErrors: false,
                      worklist_name: "",
                      SpecialtyId: "",
                      ClientId: "",
                      ProcessId: "",
                      project_member: "",
                      project_owner: "",
                      duration: "",
                    });
                  }}
                >
                  <span className="svg-icon svg-icon-1">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                      <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                    </svg>
                  </span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="modal-body scroll-y my-5">
                <form>
                  <div className="row mb-4">
                    <div className="col-6">
                      <label className="required form-label">Project Name</label>
                      <input className="form-control" type="text" name="worklist_name" placeholder="" {...register("worklist_name", registerOptions.worklist_name)} />
                      <small className="text-danger">{errors?.worklist_name && errors.worklist_name.message}</small>
                    </div>
                    <div className="col-6">
                      <label className=" required form-label">Client</label>
                      <select
                        className="form-select mb-2"
                        data-control="select2"
                        data-hide-search="true"
                        name="ClientId"
                        {...register("ClientId", registerOptions.ClientId)}
                        onChange={(e) => {
                          // handleClientChange(e);
                        }}
                      >
                        <option value="">---</option>
                        {masterData?.clients?.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                      <small className="text-danger">{errors?.ClientId && errors.ClientId.message}</small>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-6">
                      <label className="required form-label">Type</label>
                      <select className="form-select mb-2" data-control="select2" data-hide-search="true" name="ProcessId" {...register("ProcessId", registerOptions.ProcessId)}>
                        <option value="">---</option>
                        {masterData?.processes?.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                      <small className="text-danger">{errors.ProcessId && errors.ProcessId.message}</small>
                    </div>
                    <div className="col-6">
                      <label className="required form-label">Tech Stack</label>
                      <Controller name="SpecialtyId" control={control} rules={{ required: "Required" }} render={({ field }) => <Select {...field} isMulti options={renderSpecialties()} groupBy="label" styles={customStyles} />} />
                      <small className="text-danger">{errors?.SpecialtyId && errors.SpecialtyId.message}</small>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-lg-6">
                      <label className="fs-6 fw-semibold mb-2 required form-label">
                        <span>Start Date</span>
                      </label>
                      <Controller
                        name="start_date"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, onBlur, ...fieldProps } }) => (
                          <Flatpickr
                            options={options_for_end_date}
                            onChange={(dates, currentdateString) => {
                              onChange(currentdateString);

                              const durationValue = getValues("duration");
                              if (parseInt(durationValue) >= 0) {
                                const start_date = currentdateString;
                                if (moment(start_date, "DD/MM/YYYY", true).isValid()) {
                                  addWeeks(start_date, durationValue);
                                }
                              }
                            }}
                            className="form-control"
                            placeholder=""
                            name="start_date"
                          />
                        )}
                        rules={{ required: "Required" }}
                      />
                      <small className="text-danger">{errors?.start_date && errors.start_date.message}</small>
                    </div>
                    <div className="col-lg-6">
                      <OverlayTrigger placement={"top"} overlay={<Tooltip>Possible values: 0 to 100 </Tooltip>}>
                        <label className="form-label required ">
                          Duration (weeks)<i className="fas fa-exclamation-circle ms-1 fs-6"></i>
                        </label>
                      </OverlayTrigger>
                      <input
                        min={0}
                        className="form-control"
                        type="number"
                        name="duration"
                        placeholder=""
                        {...register("duration", registerOptions.duration)}
                        onChange={(e) => {
                          const numberOfWeeks = parseInt(e.target.value, 10) || 0;
                          if (numberOfWeeks >= 0) {
                            const start_date = getValues("start_date");
                            addWeeks(start_date, numberOfWeeks);
                          }
                        }}
                      />
                      <small className="text-danger">{errors?.duration && errors.duration.message}</small>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-6">
                      <label className="required form-label">Owner</label>
                      <Controller name="project_owner" control={control} rules={{ required: "Required" }} render={({ field }) => <Select {...field} options={renderAllUsers("owners")} />} />
                      <small className="text-danger">{errors?.project_owner && errors.project_owner.message}</small>
                    </div>
                    <div className="col-lg-6">
                      <label className="fs-6 fw-semibold mb-2 required form-label">
                        <span>End Date</span>
                      </label>
                      <Controller
                        name="end_date"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, ...fieldProps } }) => (
                          <Flatpickr
                            value={getValues("end_date")}
                            options={options_for_end_date}
                            onChange={(dates, currentdateString) => {
                              onChange(currentdateString);
                              const start_date = getValues("start_date");
                              setDurationBasedOnEndDate(start_date, currentdateString);
                            }}
                            className="form-control"
                            placeholder=""
                            name="end_date"
                          />
                        )}
                        rules={{ required: "Required" }}
                      />
                      <small className="text-danger">{errors?.end_date && errors.end_date.message}</small>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12">
                      <label className="required form-label">Members</label>
                      <Controller name="project_member" control={control} rules={{ required: "Required" }} render={({ field }) => <Select {...field} isMulti options={renderAllUsers("members")} />} />
                      <small className="text-danger">{errors?.project_member && errors.project_member.message}</small>
                    </div>
                  </div>
                  <div className="text-center pt-8">
                    <button
                      type="reset"
                      className="btn btn-light me-3"
                      data-kt-users-modal-action="cancel"
                      onClick={(e) => {
                        // setIsDisabled(true);
                        setShowModalBox(false);
                        reset({
                          keepErrors: false,
                          worklist_name: "",
                          SpecialtyId: "",
                          ClientId: "",
                          ProcessId: "",
                          project_member: "",
                          project_owner: "",
                          duration: "",
                        });
                      }}
                    >
                      Cancel
                    </button>
                    <button type="button" id="savebutton" className="btn btn-primary me-4" onClick={(e) => handleSubmitPreview("save")}>
                      <span className="indicator-label">Save</span>
                      <span className="indicator-progress">
                        Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                    <button type="button" className="btn btn-primary save-allocate-btn" onClick={(e) => handleSubmitPreview("allocate")}>
                      <span className="indicator-label">Save & Open</span>
                      <span className="indicator-progress">
                        Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  </div>
                </form>
              </Modal.Body>
            </div>
          </div>
        </Modal>
      </Container>
    </>
  );
};

export default WatchListBodyAddVol;
