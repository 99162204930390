import { useContext, useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import TaskContext from "../../Context/Chartcontext";
import "./ChartForm.css";
import { imageIdGenerate } from "../../utils/custom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { fetchProjectEpicsAndSprints, getProjectMembers } from "../../services/managerWorkListService";
import { handleSizeChange } from "../../utils/custom";
import { deleteButton } from "../../services/chartService";
import { getAttachments } from "../../services/userService";

function ChartInfo({ masterData, enabled, getdata, data, handleDateTimeChange, handleSelectChange, errorsForChat, mainData, handleKeypress, errorsFromAuditorToChart, setIsTouched, taskType, setTaskType, taskTags, setTaskTags, dataSuccessfullyAdded, configurationProvider, configurationConstants }) {
  const { defaultHold, setDefaultHold, setMilestone, milestone, inpval, assignee, setAssignee, epic, setEpic, sprint, setSprint, priority, setPriority } = useContext(TaskContext);
  const [epicAndSprintList, setEpicAndSprintList] = useState({ epics: [], sprints: [] });
  const [projectMembers, setProjectMembers] = useState([]);
  const [attachment, setAttachments] = useState([]);
  const [count, setCount] = useState(false);
  const task_id = data?.Id;
  let tags;

  if (data?.Tags) {
    tags = data?.Tags?.map((res) => res)[0];
  }

  const options = masterData?.tags?.filter((tag) => {
    return tag.value === 2 && tag;
  });

  useEffect(() => {
    if (data.WorklistId) {
      fetchProjectEpicsAndSprints(data.WorklistId).then((response) => {
        if (response.success) {
          setEpicAndSprintList({ epics: response?.data?.epics ?? [], sprints: response?.data?.sprints ?? [] });
        }
      });

      getProjectMembers(data.WorklistId).then((response) => {
        setProjectMembers(response?.data);
      });

      if (task_id) {
        getAttachments(task_id).then((response) => {
          if (response) {
            setAttachments(response);
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [data.WorklistId, count, dataSuccessfullyAdded]);

  const renderHoldList = () => {
    return data.holdreason?.map((data) => ({
      label: data.name,
      value: data.id,
    }));
  };

  const renderSprintList = () => {
    return epicAndSprintList?.sprints?.map((data) => ({
      label: data.label,
      value: data.value,
    }));
  };

  const renderPriorityList = () => {
    return data?.masterData?.priorities?.map((data) => ({
      label: data.name,
      value: data.id,
    }));
  };

  const renderEpicList = () => {
    return epicAndSprintList?.epics?.map((data) => ({
      label: data.label,
      value: data.value,
    }));
  };

  const renderMilestoneList = () => {
    return data.masterData?.milestones?.map((data) => ({
      label: data.name,
      value: data.id,
    }));
  };

  const renderProjectMembersList = () => {
    return projectMembers?.map((member) => ({
      label: `${member.first_name} ${member.last_name}`,
      value: member.id,
      image: member.image_url ?? `${process.env.PUBLIC_URL}/assets/media/avatars/300-${imageIdGenerate(member?.id)}.jpg`,
    }));
  };

  const renderTaskTypes = () => {
    return data.masterData?.task_type?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };

  const handleHoldChange = (event) => {
    setDefaultHold(event);
  };

  const handleMilestoneChange = (event) => {
    setMilestone(event);
  };

  const handleEpicChange = (event) => {
    setEpic({
      label: event.label ?? epic?.label,
      value: event.value ?? epic?.value,
    });
  };

  const handleSprintChange = (event) => {
    setSprint({
      label: event.label ?? sprint?.label,
      value: event.value ?? sprint?.value,
    });
  };

  const handlePriorityChange = (event) => {
    setPriority({
      label: event.label ?? sprint?.label,
      value: event.value ?? sprint?.value,
    });
  };

  const handleTaskAssigneeChange = (event) => {
    setAssignee({
      label: `${event.label}`,
      value: event.value,
      image: event.image,
    });
  };

  const handleTaskType = (event) => {
    setTaskType({
      label: event.label ?? taskTypeValue,
      value: event.value ?? data?.TaskTypes,
    });
  };
  const handleTags = (event) => {
    setTaskTags({
      label: event?.label,
      value: event?.value,
      id: event?.id,
    });
  };

  const handleDeleteButton = (id, key) => {
    const deletebutton = {
      key: { [key]: id },
    };
    deleteButton(deletebutton);
    setCount(!count);
  };

  const taskTypeValue = data?.TaskType === 1 ? "Task" : data?.TaskType === 2 ? "Story" : data?.TaskType === 3 ? "Bug" : "";
  const taskTypeDefaultValue = { label: taskTypeValue, value: data?.TaskType };

  return (
    <>
      <div className="card card-flush mb-6 mb-xl-9">
        <div className="card-header mt-6 minimize">
          <div className="card-title flex-column">
            <h2 className="mb-1">Task Info</h2>
            <div className="fs-6 fw-semibold text-muted">All relevant task details</div>
          </div>
          <div className="card-toolbar mt-0">
            <button className="btn btn-icon btn-sm btn-light-primary justify-content-center">
              <i className="fas fa-minus" />
            </button>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="card-body d-flex flex-column pb-0">
            <div className="d-flex flex-column gap-5 gap-md-7">
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-6">
                  <label className="form-label">Task Type</label>
                  <Select id="task_type" name="task_type" options={renderTaskTypes()} isMulti={false} value={taskType ?? taskTypeDefaultValue} isDisabled={false} onChange={handleTaskType} />
                </div>
                <div className="col-xl-9 col-lg-9 col-6">
                  <label className="required form-label">Task Name</label>
                  <input className="form-control" autoComplete="off" value={inpval?.name || ""} defaultValue={data?.TaskName} onChange={getdata} onKeyUp={handleKeypress} name="name" disabled={false} />
                  {errorsForChat?.name && (inpval?.name === null || inpval?.name?.trim() === "") && <p className="help is-danger errorDanger">{errorsForChat?.name}</p>}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label className="required form-label">Description</label>
                  {<textarea rows="3" className="form-control" name="description" value={inpval?.description || ""} onChange={getdata} onKeyUp={handleKeypress} disabled={false} onFocus={handleSizeChange} onBlur={handleSizeChange} />}
                  {errorsForChat?.description && <p className="help is-danger errorDanger">{errorsForChat?.description}</p>}
                </div>
              </div>
              <div>
                <fieldset>
                  <legend>Attachment Links</legend>
                  <div className="card card-flush mb-4">
                    <div className="card-body pt-0">
                      <div className="" data-kt-ecommerce-catalog-add-product="auto-options">
                        <div id="kt_ecommerce_add_product_options">
                          <div id="location">
                            <div className="applicant-fields">
                              <div className="form-group">
                                <div data-repeater-list="kt_ecommerce_add_product_options" className="d-flex flex-column gap-3">
                                  <div data-repeater-list="kt_ecommerce_add_product_options" className="d-flex flex-column gap-3">
                                    {Array.isArray(attachment) &&
                                      attachment?.map((item, i) => (
                                        <div data-repeater-item="" className="form-group d-flex flex-wrap align-items-center gap-5" key={i}>
                                          <input type="text" className="form-control mw-100 w-85" name="product_option_value" value={(configurationProvider.editedData[configurationConstants.GENERAL_CONFIGURATION][configurationConstants.ATTACHMENTS] ?? {})[item?.id] ?? item?.link} placeholder={item?.link} onChange={(e) => configurationProvider.onEditFieldChange(configurationConstants.GENERAL_CONFIGURATION, configurationConstants.ATTACHMENTS, item.id, e.target.value)} />

                                          {item?.link && <button className="btn btn-icon btn-sm btn-light-primary justify-content-center" title="Open in new tab" onClick={() => {
                                            window.open(item?.link, '_blank');
                                          }}><i className="fas fa-up-right-from-square"></i></button>}

                                          <button type="button" data-repeater-delete="" className="btn btn-sm btn-icon btn-light-danger" title="Delete" onClick={() => { handleDeleteButton(item?.id, configurationConstants.ATTACHMENTS); }} >
                                            <span className="svg-icon svg-icon-1">
                                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor" /> <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor" />{" "}
                                              </svg>
                                            </span>
                                          </button>
                                        </div>
                                      ))}
                                  </div>
                                  {(configurationProvider.submitData[configurationConstants.GENERAL_CONFIGURATION]?.attachments ?? []).map((e, i) => {
                                    return (
                                      <div id="kt_ecommerce_add_product_options">
                                        <div id="location">
                                          <div className="applicant-fields">
                                            <div className="form-group">
                                              <div data-repeater-list="kt_ecommerce_add_product_options" className="d-flex flex-column gap-3">
                                                <div data-repeater-item="" className="form-group d-flex flex-wrap align-items-center gap-5">
                                                  <input type="text" className="form-control mw-100 w-85" onChange={(e) => configurationProvider.onFieldChange(configurationConstants.GENERAL_CONFIGURATION, configurationConstants.ATTACHMENTS, i, e.target.value)} />
                                                  <button type="button" data-repeater-delete="" className="btn btn-sm btn-icon btn-light-danger" onClick={() => configurationProvider.onDeleteClick(configurationConstants.GENERAL_CONFIGURATION, configurationConstants.ATTACHMENTS, i)}>
                                                    <span className="svg-icon svg-icon-1">
                                                      {" "}
                                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        {" "}
                                                        <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1" transform="rotate(-45 7.05025 15.5356)" fill="currentColor" /> <rect x="8.46447" y="7.05029" width="12" height="2" rx="1" transform="rotate(45 8.46447 7.05029)" fill="currentColor" />{" "}
                                                      </svg>{" "}
                                                    </span>
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group mt-5">
                            <button type="button" data-repeater-create="" className="btn btn-sm btn-light" onClick={() => configurationProvider.onAddClick(configurationConstants.GENERAL_CONFIGURATION, configurationConstants.ATTACHMENTS)}>
                              <span className="svg-icon svg-icon-2">
                                {" "}
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  {" "}
                                  <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1" transform="rotate(-90 11 18)" fill="currentColor" /> <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor" />{" "}
                                </svg>{" "}
                              </span>
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              <div className="row">
                <div className="col-xl-3 col-lg-3 col-6">
                  <label className="form-label">Assignee</label>
                  <Select
                    id="assignee"
                    name="assignee_id"
                    options={renderProjectMembersList()}
                    isMulti={false}
                    value={assignee}
                    isDisabled={false}
                    onChange={handleTaskAssigneeChange}
                    formatOptionLabel={(user) => (
                      <div value={user?.value}>
                        <img width="30" height="30" style={{ borderRadius: "50%", marginRight: "10px" }} src={user.value ? user?.image ?? `${process.env.PUBLIC_URL}/assets/media/avatars/300-${imageIdGenerate(user?.value)}.jpg` : `${process.env.PUBLIC_URL}/assets/media/avatars/blank.png`} alt="user.img" />
                        <span className="fw-semibold">{user.value ? user?.label ?? "" : "Unassigned"}</span>
                      </div>
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-3 col-6">
                  <label className="form-label">Start date</label>
                  <Flatpickr className="form-control" options={{ dateFormat: "d-m-Y", animate: true }} value={inpval?.start_date} name="start_date" onChange={handleDateTimeChange} disabled={false} />
                  {errorsForChat?.start_date && inpval?.start_date === null && <p className="help is-danger errorDanger">{errorsForChat?.start_date}</p>}
                </div>
                <div className="col-xl-3 col-lg-3 col-6">
                  <label className="form-label">End date</label>
                  <Flatpickr className="form-control" options={{ dateFormat: "d-m-Y", minDate: data?.StartDate }} value={inpval?.end_date} name="end_date" onChange={handleDateTimeChange} disabled={false} />
                  {errorsForChat?.end_date && inpval?.end_date === null && <p className="help is-danger errorDanger">{errorsForChat?.end_date}</p>}
                </div>
                <div className="col-xl-3 col-lg-3 col-6">
                  <OverlayTrigger placement={"top"} overlay={<Tooltip> Formats supported: 02:30, 2h30m, 2h, 30m, 2.5 </Tooltip>}>
                    <div>
                      <label className="required form-label">Estimation</label>
                      <i className="fas fa-exclamation-circle ms-1 fs-6"></i>
                    </div>
                  </OverlayTrigger>
                  <input className="form-control" name="estimation" value={inpval?.estimation || ""} onChange={getdata} onKeyUp={handleKeypress} autoComplete="off" />
                  {errorsForChat?.estimation && <p className="help is-danger errorDanger">{errorsForChat?.estimation}</p>}
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-3 col-6">
                  <label className="form-label">Release Version</label>
                  <input className="form-control" name="release_version" value={inpval?.release_version || ""} onChange={getdata} onKeyUp={handleKeypress} autoComplete="off" />
                </div>
                <div className="col-xl-3 col-lg-3 col-6">
                  <label className="form-label">Tags</label>
                  <Select className="col-xl-12 col-12" value={taskTags?.length !== 0 ? taskTags : tags} options={options} onChange={handleTags} />
                </div>
              </div>
              <div>
                <label className=" form-label"></label>
                <input type="hidden" className="form-control" name="ChartMetumId" onChange={getdata} readOnly disabled={!enabled} />
              </div>
            </div>
          </div>
          <div className="separator separator-dashed my-6" />
          <div className="card card-flush mb-6 mb-xl-9">
            <div className="card-body d-flex flex-column pt-0">
              <div className="d-flex flex-column gap-5 gap-md-7">
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-3">
                    <label className="form-label">Priority</label>
                    <Select id="priority" name="priorityId" menuPlacement={"auto"} isSearchable={true} options={renderPriorityList()} isMulti={false} value={priority} defaultValue={{ label: "", value: 1 }} onChange={handlePriorityChange} />
                    {/* {errorsForChat?.MilestoneId && <p className="help is-danger errorDanger">{errorsForChat?.MilestoneId}</p>} */}
                  </div>
                  <div className="col-xl-3 col-lg-3 col-3">
                    <label className="form-label">Milestone</label>
                    <Select id="milestone" name="MilestoneId" menuPlacement={"auto"} isSearchable={true} options={renderMilestoneList()} isMulti={false} value={milestone} defaultValue={{ label: "Todo", value: 1 }} onChange={handleMilestoneChange}>
                      {mainData.defaultMilestone?.map((option) => (
                        <option key={option?.id} value={option?.id}>
                          {option?.name}
                        </option>
                      ))}
                    </Select>
                    {/* {errorsForChat?.MilestoneId && <p className="help is-danger errorDanger">{errorsForChat?.MilestoneId}</p>} */}
                  </div>
                  <div className="col-xl-6 col-lg-6 col-6">
                    <label className="form-label">Hold reason</label>
                    {<Select name="HoldReasons" menuPlacement={"auto"} isSearchable={true} options={renderHoldList()} isMulti={true} isDisabled={false} value={defaultHold ?? data.HoldReasons} onChange={handleHoldChange}></Select>}
                    {/* {errorsForChat?.HoldReasons && (defaultHold === undefined || defaultHold?.length === 0) && <p className="help is-danger errorDanger">{errorsForChat?.HoldReasons}</p>} */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-6">
                    <label className="form-label">Sprint</label>
                    <Select id="sprint" name="SprintId" menuPlacement={"auto"} isSearchable={true} options={renderSprintList()} isMulti={false} value={sprint} defaultValue={{ label: "", value: 1 }} onChange={handleSprintChange} />
                    {/* {errorsForChat?.MilestoneId && <p className="help is-danger errorDanger">{errorsForChat?.MilestoneId}</p>} */}
                  </div>
                  <div className="col-xl-6 col-lg-6 col-6">
                    <label className="form-label">Epic</label>
                    <Select id="epic" name="EpicId" menuPlacement={"auto"} isSearchable={true} options={renderEpicList()} isMulti={false} value={epic} defaultValue={{ label: "", value: 1 }} onChange={handleEpicChange} />
                    {/* {errorsForChat?.MilestoneId && <p className="help is-danger errorDanger">{errorsForChat?.MilestoneId}</p>} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ChartInfo;
