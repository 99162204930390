import React, { useEffect, useState } from "react";
import Select from "react-select";
import ReportsModal from "./ReportsModal";
import managerAPI from "../../../apis/manager/managerAPI";
import TimesheetLogHoursModal from "./TimesheetLogHoursModal";
import { filterWithGetAllTimesheet, fetchProjectForTimesheet, fetchUserForTimesheet, exportAttendance } from "../../../services/timeSheetService";
import localStorageStore from "../../../utils/localStorageStore";
import role from "../../../utils/role";
import { Link } from "react-router-dom";
import moment from "moment";
const TimesheetTable = ({ masterData }) => {
  const fields = ["project_name", "date", "assignee_name", "description", "hours_calc"];
  const decryptRole = localStorageStore.getRole();
  const [selectedProjectName, setSelectedProjectName] = useState([]);
  const [users, setUsers] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [showModalBox, setShowModalBox] = useState(false);
  const [showTable, setShowTable] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [selectedUserId, setSelectedUserId] = useState(decryptRole === role.TeamLead ? [localStorage.getItem("loginUserId")] : []);
  const [selectedLogDate, setSelectedLogDate] = useState("");
  const [rerenderLogDate, setReRenderLogDate] = useState(true);
  const [modalUserId, setModalUserId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [date, setDate] = useState("");
  const [show, setShow] = useState(false);
  const currentMonth = moment().format("M");
  const [currentUser, setCurrentUser] = useState([]);
  const [lastSelectedProject, setLastSelectedProject] = useState(0)

  useEffect(() => {
    fetchTimeSheet();
    fetchProjectList();
    if (decryptRole !== role.Member) {
      fetchProjectMember();
    }

    if (decryptRole === role.TeamLead) {
      setSelectedUserId([localStorage.getItem("loginUserId")]);
      setCurrentUser([{ label: document.querySelector(".header-user-name").innerHTML, value: localStorage.getItem("loginUserId") }]);
    }
    // eslint-disable-next-line
  }, [rerenderLogDate]);

  useEffect(() => {
    handleSubmitFilter();
    // eslint-disable-next-line
  }, [currentUser]);

  const firstDay = moment(`${selectedLogDate || currentMonth}`, "M")
    .startOf("month")
    .format("MM/DD/YYYY");
  const lastDay = moment(`${selectedLogDate || currentMonth}`, "M")
    .endOf("month")
    .format("MM/DD/YYYY");

  let obj = {
    users: selectedUserId,
    projects: selectedProjectId,
    month: selectedLogDate || currentMonth, // Use selectedLogDate if available, otherwise use currentMonth
  };

  let filterPayload = {};

  if (selectedUserId && selectedUserId?.length > 0) {
    filterPayload.assignee_name = selectedUserId;
  }

  if (firstDay && lastDay) {
    filterPayload.date = `${firstDay}-${lastDay}`;
  }

  if (selectedProjectName && selectedProjectName?.length > 0) {
    filterPayload.project_name = selectedProjectName;
  }

  const fetchTimeSheet = async () => {
    const fetchTimesheetList = await filterWithGetAllTimesheet(obj);
    setShowTable(fetchTimesheetList?.data);
    window.KTMenu.init();
  };

  const handleExport = async () => {
    fetchReports(fields, true);
  };

  const handelExportAttendance = async () => {
    const response = await exportAttendance(obj);

    const blob = new Blob([response], { type: "application/vnd.ms-excel; charset=utf-8" });
    const url = URL.createObjectURL(blob);
    let alink = document.createElement("a");
    alink.href = url;
    alink.download = "";
    alink.click();

    try {
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleRerenderdata = (response) => {
    setReRenderLogDate(response);
  };
  const fetchProjectList = async () => {
    const fetchProjectListTimeSheet = await fetchProjectForTimesheet();
    setProjectList(fetchProjectListTimeSheet);
  };
  const fetchProjectMember = async () => {
    const response = await fetchUserForTimesheet();
    setUsers(response);
  };

  const renderProjectOptions = () => {
    return projectList?.map((project) => ({
      label: `${project?.name} ( ${project?.worklist_no} )`,
      value: project?.id,
    }));
  };

  const handleProjectChange = (selectedOption) => {
    const projectId = selectedOption?.map((item) => item.value) || [];
    const projectName = selectedOption?.map((item) => item?.label) || [];
    const projectNameForExport = projectName?.map((entry) => entry?.replace(/\([^)]+\)/, "").trim());
    setSelectedProjectId(projectId);
    setSelectedProjectName(projectNameForExport);
  };

  const renderUserOptions = () => {
    return users?.map((user) => ({
      label: `${user?.firstName} ${user?.lastName}`,
      value: user?.u_id,
    }));
  };

  const handleUserDetails = (event) => {
    setCurrentUser(event);
    const selectedUserIds = event?.map((item) => item.value) || [];
    setSelectedUserId(selectedUserIds);
  };

  const renderLogDate = () => {
    const logDate = [
      { label: "January", value: "1" },
      { label: "February", value: "2" },
      { label: "March", value: "3" },
      { label: "April", value: "4" },
      { label: "May", value: "5" },
      { label: "June", value: "6" },
      { label: "July", value: "7" },
      { label: "August", value: "8" },
      { label: "September", value: "9" },
      { label: "October", value: "10" },
      { label: "November", value: "11" },
      { label: "December", value: "12" },
    ];

    return logDate;
  };

  const handleLogDateChange = (selectedOption) => {
    const selectedMonth = parseInt(selectedOption?.value);
    setSelectedLogDate(selectedMonth);
  };

  const handleSubmitFilter = async () => {
    const fetchTimesheetList = await filterWithGetAllTimesheet(obj);
    setShowTable(fetchTimesheetList?.data);
  };

  const calculateTotalsDays = () => {
    const totals = {};
    // Loop through the records and update the totals for each column
    showTable?.records?.forEach((record) => {
      record?.data?.forEach((rowData, j) => {
        if (j >= 3) {
          if (!totals[j]) {
            totals[j] = 0;
          }
          totals[j] += rowData.row_data || 0;
        }
      });
    });
    for (const key in totals) {
      if (totals.hasOwnProperty(key)) {
        totals[key] = parseFloat(totals[key].toFixed(2));
      }
    }

    return totals;
  };
  const totalValuesDays = calculateTotalsDays();

  const calculateTotals = () => {
    const totals = {};
    // Loop through the records and update the totals for each column
    showTable?.records?.forEach((record) => {
      record?.data?.forEach((rowData, j) => {
        if (j === 2) {
          if (!totals[j]) {
            totals[j] = 0;
          }
          totals[j] += rowData.row_data || 0;
        }
      });
    });
    for (const key in totals) {
      if (totals.hasOwnProperty(key)) {
        totals[key] = parseFloat(totals[key].toFixed(2));
      }
    }
    return totals;
  };
  const totalValues = calculateTotals();

  const handleViewLog = async (project_id, user_id, date) => {
    setShow(!show);
    setModalUserId(user_id);
    setProjectId(project_id);
    setDate(date);
  };

  const fetchReports = async (fields, exportFile = false, isLocal, forcePage = false) => {
    try {
      let res = await managerAPI.getReports(
        {
          export: exportFile,
          fields: fields,
          filters: filterPayload,
        },
        {
          page: forcePage ? forcePage : 1,
        },
        exportFile
      );
      if (exportFile) {
        window.location.assign(res.result.url);
      }
    } catch (e) {}
  };

  return (
    <>
      <div className="row mb-4">
        <div className="col-1 px-5 mt-8">
          <span className="h1 d-flex">Filter</span>
        </div>
        <div className="col-3 px-5">
          <div className="d-flex">
            <div className="input-group me-2 flex-column">
              <label className="form-label">Select Project </label>
              <Select options={renderProjectOptions()} onChange={handleProjectChange} isMulti={true} placeholder="Select Project..." />
            </div>
          </div>
        </div>
        <div className="col-2 px-5">
          <div className="d-flex">
            <div className="input-group me-2 flex-column">
              <label className="form-label">Log Date </label>
              <Select options={renderLogDate()} isMulti={false} placeholder="Select Log Date..." onChange={handleLogDateChange} defaultValue={renderLogDate().find((option) => option.value === currentMonth)} />
            </div>
          </div>
        </div>
        {decryptRole !== role.Member && (
          <div className="col-2 px-5">
            <div className="d-flex">
              <div className="input-group me-2 flex-column">
                <label className="form-label">Select User </label>
                <Select options={renderUserOptions()} value={currentUser} onChange={handleUserDetails} isMulti={true} placeholder="Select User ...." />
              </div>
              <div></div>
            </div>
          </div>
        )}
        <div className="col-2 px-5">
          <button type="button" className="btn btn-light-primary me-3 d-flex py-3 mt-7" onClick={handleSubmitFilter}>
            Search &#8594;
          </button>
        </div>
        <div className="col-2 px-5 d-flex justify-content-end ms-auto">
          <div>
            {decryptRole === role.Admin && (
              <Link onClick={(e) => e.preventDefault()} style={{ maxWidth: "110px", borderTopRightRadius: "30px", borderBottomRightRadius: "30px" }} className="btn btn-primary me-3 d-flex py-3 mt-7 text-btn " data-kt-menu-trigger="{default:'click', sm: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                <span className="svg-icon svg-icon-2">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="#FFFFFF" />
                    <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="#FFFFFF" />
                    <path opacity="0.3" d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#FFFFFF" />
                  </svg>
                </span>
                Export
              </Link>
            )}
            <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px" data-kt-menu="true" data-kt-element="theme-mode-menu">
              <div className="menu-item px-3 my-0">
                <Link onClick={handleExport} className="menu-link px-3 py-2" href="../assets/reports/report.xlsx" download data-kt-element="mode" data-kt-value="light">
                  <span className="menu-icon" data-kt-element="icon">
                    <span className="svg-icon svg-icon-2">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="#FFFFFF" />
                        <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="#FFFFFF" />
                        <path opacity="0.3" d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#FFFFFF" />
                      </svg>
                    </span>
                  </span>
                  <span className="menu-title">Timesheet</span>
                </Link>
              </div>
              <div className="menu-item px-3 my-0">
                <Link onClick={handelExportAttendance} className="menu-link px-3 py-2" href="../assets/reports/report.xlsx" download data-kt-element="mode" data-kt-value="dark">
                  <span className="menu-icon" data-kt-element="icon">
                    <span className="svg-icon svg-icon-2">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="#FFFFFF" />
                        <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="#FFFFFF" />
                        <path opacity="0.3" d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#FFFFFF" />
                      </svg>
                    </span>
                  </span>
                  <span className="menu-title">Attendance</span>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-primary me-3 d-flex py-3 mt-7"
              onClick={(e) => {
                setShowModalBox(true);
              }}
            >
              <span className="svg-icon svg-icon-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                  <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                </svg>
              </span>
              Log Time
            </button>
          </div>
        </div>
        <div></div>
      </div>

      <div className="card">
        <div className="card-header border-0 pt-6">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-gray-800">Timesheet</span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-4">
          <div className="row">
            <div className="col-5" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
              <table className="table to-do-table align-middle table-row-dashed fs-6 gy-5 gs-7" id="kt_table_users">
                <thead>
                  <tr className="text-start fw-bold fs-7 text-uppercase gs-0 border">
                    {showTable?.headers?.map(
                      (item, i) =>
                        i < 3 && (
                          <th key={i} className="min-w-40px" style={{ paddingTop: "20px", paddingBottom: "19px" }}>
                            {item?.heading}
                          </th>
                        )
                    )}
                  </tr>
                </thead>
                <tbody className="text-gray-600 fw-semibold border">
                  {showTable?.records?.map((record, i) => (
                    <tr key={i}>
                      {record?.data?.map(
                        (rowData, j) =>
                          j < 3 && (
                            <td key={j} style={{ minWidth: j === 0 ? "50%" : j === 1 ? "35%" : "15%" }}>
                              {rowData.row_data}
                            </td>
                          )
                      )}
                    </tr>
                  ))}
                </tbody>
                {showTable?.records?.length !== 0 && (
                  <tfoot className="border">
                    <tr>
                      <td className="fw-bold"></td>
                      <td className="fw-bold">TOTAL</td>
                      {Object.keys(totalValues).map((key, index) => (
                        <td key={index}>{totalValues[key]}</td>
                      ))}
                    </tr>
                  </tfoot>
                )}
              </table>
              {showTable?.records?.length === 0 && <div>No Records Found ...</div>}
            </div>

            <div className="col-7" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
              <div className="table-responsive">
                <table className="table to-do-table align-middle table-row-dashed fs-6 gy-5 gs-2" id="kt_table_users">
                  <thead>
                    <tr className="text-start fw-bold fs-7 text-uppercase gs-0 border text-center">
                      {showTable?.headers?.map(
                        (item, i) =>
                          i > 2 && (
                            <th key={i} className="min-w-70px mw-70px">
                              {item?.date} <br />
                              {item?.day}
                            </th>
                          )
                      )}
                    </tr>
                  </thead>
                  <tbody className="text-gray-600 fw-semibold border">
                    {showTable?.records?.map((record, i) => (
                      <tr key={i} className="text-center">
                        {record?.data?.map(
                          (rowData, j) =>
                            j > 2 && (
                              <td
                                key={j}
                                className="min-w-70px mw-70px"
                                onClick={() => {
                                  handleViewLog(record.data[0].project_id, record.data[1].user_id, rowData.date);
                                }}
                              >
                                {rowData.row_data}
                              </td>
                            )
                        )}
                      </tr>
                    ))}
                  </tbody>
                  {showTable?.records?.length !== 0 && (
                    <tfoot className="border">
                      <tr className="text-center">
                        {Object.keys(totalValuesDays).map((key, index) => (
                          <td key={index} className="min-w-70px mw-70px">
                            {totalValuesDays[key]}
                          </td>
                        ))}
                      </tr>
                    </tfoot>
                  )}
                </table>
                {/* {showTable?.records?.length === 0 && <div>No Records Found ...</div>} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {show && <ReportsModal setShow={setShow} show={show} projectId={projectId} modalUserId={modalUserId} date={date} handleRerenderdata={handleRerenderdata} masterData={masterData} />}
      {showModalBox && <TimesheetLogHoursModal lastSelectedProject={lastSelectedProject} setLastSelectedProject={setLastSelectedProject} showModalBox={showModalBox} setShowModalBox={setShowModalBox} handleRerenderdata={handleRerenderdata} projectList={projectList} masterData={masterData} />}
    </>
  );
};
export default TimesheetTable;
